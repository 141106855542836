import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { LegalOnboardingService } from '../../pages/legal-onboarding-2.0/services/legal-onboarding.service';
import { DataService } from '../../services/data.service';
import { Subject } from 'rxjs';
import { Edge, Node, Layout } from '@swimlane/ngx-graph';

@Component({
  selector: 'app-relation-graph',
  templateUrl: './relation-graph.component.html',
  styleUrls: ['./relation-graph.component.scss']
})
export class RelationGraphComponent implements OnChanges {

  @Input() reportData;

reportDataUnique:any={}
  nodes: Node[];

  links: Edge[];
  layout: String | Layout = 'colaForceDirected';

  center$: Subject<boolean> = new Subject();
  zoomToFit$: Subject<boolean> = new Subject();
  update$: Subject<any> = new Subject();
  zoomLevel: number = 0.6;
  maxZoomLevel: number = 4;
  minZoomLevel: number = 0.1;


  allRelations: any;
  ngxDataStructure = {
    node: [], link: []
  }
  toggleNgxData: boolean = false;
  displayGraph = false
  detailedGraph: boolean;

  constructor(private _los: LegalOnboardingService, private _data: DataService) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.reportData && changes.reportData.currentValue) {
      this.reportData = changes.reportData.currentValue

      if(this.reportData.length){
        this.reportDataUnique= this.reportData[0]
      }
     
    }

    if (this.reportData.length) {
      await this.getRelationGraphData()
    }
    else{
      this.displayGraph=true
    }
    console.log(this.reportData, 'data')
    // this.centerFun()
    // this.zoomToFitFun()
  }

  async getRelationGraphData() {
    this.displayGraph = false
    // this.allRelations= [...this.reportDataUnique.relationshipList]
    // this.formatNgxGraphData([...this.reportDataUnique.relationshipList])
    try {
      const res = await this._los.getRelationGraphData(this.reportData[0].guid).toPromise()
      this.allRelations = res.data
      this.formatNgxGraphData(res.data)
      this._data.changeLoaderVisibility(false); this.interval()
    } catch (error) {
      this._data.changeLoaderVisibility(false); this.interval()
    }
  }

  //using this interval bcoz when we click on entity in RSGraph then for first time its showing nothing.
  interval() {
  }
 

  formatNgxGraphData(data) {
 
    if (data.length) {

      //setting node and link to empty arrays if toggle switch runs then this will run as expected
      this.ngxDataStructure.node = []
      this.ngxDataStructure.link = []
      console.log(data, this.reportDataUnique, 'ngx-graph', 'base Data')
      //Manually creating center node.
      let centerNode: any = {}

      //to remove duplicate node fullNames this function is used
      const uniqueNames = data.filter((obj, index, self) => {
        const firstIndex = self.findIndex(o => o.fullName === obj.fullName);
        return firstIndex === index;
      });


      //For center node-id, guid of entity details is taken
      centerNode.id = this.reportDataUnique?.guid;
      centerNode.label = this.reportDataUnique?.masterData?.fullName[0]
      centerNode.circleColor = '#E54D0C'
      centerNode.type = 'Entity'
      centerNode.relationCategory = this.reportDataUnique?.masterData?.entityType == 'Organisation' ? 'Associated_Organisation' : 'Associated_Individual';
      this.ngxDataStructure.node.push(centerNode);
      //if center node fullName is present in uniqueNames then it's removed.
      const removingCenterNodeName = uniqueNames.filter(item => item.fullName !== centerNode.label)
      //all guids of this entity
      const rootGuids = this.reportDataUnique.details.map(e => e.guid)
      //based on toggle we are switching the data.We are using removingCenterNodeName data only for nodes.
      console.log('rootguids',rootGuids,removingCenterNodeName)
      let nodes = []
      for (let eh of removingCenterNodeName) {
        const nodeObj = {}
        if (this.toggleNgxData) {
          nodeObj['id'] = rootGuids.includes(eh?.childGuid) ? this.reportDataUnique?.guid : eh?.childGuid
          nodeObj['label'] = eh?.fullName
          nodeObj['circleColor'] = '#17B18C'
          nodeObj['relationCategory'] = eh?.relationCategory
        } else {
          if (rootGuids.includes(eh.parentGuid)) {
            nodeObj['id'] = rootGuids.includes(eh?.childGuid) ? this.reportDataUnique?.guid : eh?.childGuid
            nodeObj['label'] = eh?.fullName
            nodeObj['circleColor'] = '#17B18C'
            nodeObj['relationCategory'] = eh?.relationCategory
          } else {
            continue
          }
        }
        nodes.push(nodeObj)
      }
      this.ngxDataStructure.node = [...this.ngxDataStructure.node, ...nodes]
      this.nodes = this.ngxDataStructure.node
      this.nodes = [...this.nodes]
      // We are using data from api to get links. As one node can have multiple links
      let links = []
      let z = 0
      for (let eh of data) {
        const linkObj = {}
        z++
        if (this.toggleNgxData) {
          linkObj['id'] = 'link' + z + eh?.childGuid //keeping unique id for links is very important.
          linkObj['source'] = rootGuids.includes(eh?.parentGuid) ? this.reportDataUnique?.guid : eh?.parentGuid
          linkObj['target'] = rootGuids.includes(eh?.childGuid) ? this.reportDataUnique?.guid : eh?.childGuid
          linkObj['childRelation'] = eh?.childRelation
          linkObj['relationCategory'] = eh?.relationCategory
          linkObj['parentRelation'] = eh?.parentRelation
          linkObj['relationCategory'] = eh?.relationCategory
        } else {
          if (rootGuids.includes(eh.parentGuid)) {
            linkObj['id'] = 'link' + z + eh?.childGuid //keeping unique id for links is very important.
            linkObj['source'] = rootGuids.includes(eh?.parentGuid) ? this.reportDataUnique?.guid : eh?.parentGuid
            linkObj['target'] = rootGuids.includes(eh?.childGuid) ? this.reportDataUnique?.guid : eh?.childGuid
            linkObj['childRelation'] = eh?.childRelation
            linkObj['relationCategory'] = eh?.relationCategory
            linkObj['parentRelation'] = eh?.parentRelation
            linkObj['relationCategory'] = eh?.relationCategory
          } else {
            continue
          }
        }


        links.push(linkObj)
      }

      console.log('links',links)
      // consider if condition1: A value of 1st object and B value of 2nd object and B value of 1st object and A value of 2nd object is same and condition:2 A and B values of any object matching other object then we are removing the last occurring object from array of object. The overall conclusion is for the layout on link we don't have double names on it.
      const removeDuplicates = (arr) => {
        const results = [];

        for (let i = 0; i < arr.length; i++) {
          let isDuplicate = false;

          for (let j = 0; j < results.length; j++) {
            if ((arr[i].source === results[j].source && arr[i].target === results[j].target) ||
              (arr[i].target === results[j].source && arr[i].source === results[j].target)) {
              isDuplicate = true;
              break;
            }
          }

          if (!isDuplicate) {
            results.push(arr[i]);
          }
        }

        for (let i = 1; i < results.length; i++) {
          let isDuplicate = false;

          for (let j = i + 1; j < results.length; j++) {
            if ((results[i].source === results[j].target && results[i].target === results[j].source) ||
              (results[i].target === results[j].source && results[i].source === results[j].target)) {
              isDuplicate = true;
              results.splice(j, 1);
              j--;
            }
          }

          if (isDuplicate) {
            results.splice(i, 1);
            i--;
          }
        }

        return results;
      };
      const allLinks = removeDuplicates(links)
      //If the source or target guid's are not there in node then we are removing the whole object from link using these two steps.
      const nodeIds = this.ngxDataStructure.node.map(obj => obj.id);

      const finalLinks = allLinks.filter(obj => nodeIds.includes(obj.source) && nodeIds.includes(obj.target));

      this.ngxDataStructure.link = [...finalLinks]
      this.links = this.ngxDataStructure.link
      this.links = [...this.links]
      console.log(this.ngxDataStructure, 'ngx-graph')
    }
    this.displayGraph = true
  }

  handleRelationData(type: 'zoom-in' | 'zoom-out') {
    // if (this.detailedGraph !== (type === 'zoom-in')) {
    //   this.detailedGraph = (type === 'zoom-in')
    //   this.getRelationGraphData()
    // }
    this.toggleNgxData = !this.toggleNgxData
    this.getRelationGraphData()
  }

  updateGraph() {
    this.update$.next(true);
  }

  onSelect(data) {
    window.open("aml-screening/risk-individual-report/" + data.id);
  }

  centerFun() {
    this.center$.next(true);
  }

  zoomToFitFun() {
    this.zoomToFit$.next(true);
  }

  zoomIn() {

    if (this.zoomLevel <= this.maxZoomLevel) {
      this.zoomLevel = this.zoomLevel + 0.1;
    } else {
      this.zoomLevel = 4
    }

  }

  zoomOut() {

    if (this.zoomLevel >= (this.minZoomLevel + 0.1)) {
      this.zoomLevel = this.zoomLevel - 0.1;
    } else {
      this.zoomLevel = 0.1
    }
  }

}