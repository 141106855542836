import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DevOpsService } from '../../../../services/dev-ops.service';
import { DataService } from '../../../../../../services/data.service';

@Component({
  selector: 'app-view-legal-documents',
  templateUrl: './view-legal-documents.component.html',
  styleUrls: ['./view-legal-documents.component.scss']
})
export class ViewLegalDocumentsComponent implements OnInit {
  @Input() item;
  @Output() close = new EventEmitter();
  public bsModalRef: BsModalRef;
  public bsModalRef2: BsModalRef;
  updateDataItem: any;
  getFaceFile: string [] = [];
  clientId: any;
  enabledUpdateButton: boolean = false;
  isChange: boolean = false;
  faceS3Url: any;
  overrideDocForm:FormGroup=this.formbuilder.group({
    fname:[''],
    lname:[''],
    fullName:[''],
    sn:[''],
    dob:[''],
    issue:[''],
    expary:[''],
    docname: [''],
    address: [''],
    score: [''],
    status: [''],
  });
  individualFrom: FormGroup = this.formbuilder.group({
    name: [''],
    sn: [''],
    dob: [''],
    issue: [''],
    expary: [''],
    docname: [''],
    address: [''],
  });
  overrideLiveness: any[] = [
    { event: 'right_face', status: '', total_frames: '', passed_frames: ''  },
    { event: 'left_face', status: '', total_frames: '', passed_frames: ''  },
    { event: 'eye_blink', status: '', total_frames: '', passed_frames: ''  },
  ];
  selfieOverride: any = {
    confidence: 0,
    isIdentical: '',
    isVerified: false
  };
  accountId: any;
  score: number = 0;
  isLivenessVerified: boolean = false;

  constructor(private modalService: BsModalService, private dataService: DataService, private devOpsService: DevOpsService, 
    private route: ActivatedRoute, private formbuilder: FormBuilder) { }

  ngOnInit() {
    this.route.params.subscribe(({ clientID, accountId }) => {
      this.clientId = clientID;
      this.accountId = accountId;
      this.devOpsService.getDocumentVerificationResult(clientID, this.item.checkId).subscribe((res) => {
        if(res.score !== null){
          this.score = res.score
        }
      })
    });
    if(this.item && this.item?.docType === 'LIVELINESS' && this.item?.extractionResult){
      const parseData = JSON.parse(this.item?.extractionResult);
      this.overrideLiveness = parseData?.livenessRes
    }
    if(this.item && this.item?.docType === 'SELFIE' && this.item?.extractionResult){
      const parseData = JSON.parse(this.item?.extractionResult);
      this.selfieOverride = parseData?.azureFaceVerifyRes;
      this.selfieOverride['isVerified'] = false;
    }
  }

  changeFunction(){
    this.devOpsService.getDocList(this.clientId, this.accountId).subscribe((res) => {
      setTimeout(() => { this.isChange = true}, 1000);
      for(let data of res){
        if(data.checkId == this.item.checkId){
          this.updateDataItem = data;
        }
      }
      this.devOpsService.getDocumentVerificationResult(this.clientId, this.item.checkId).subscribe((res) => {
        if(res.score !== null){
          this.score = res.score
        }
      })
    })
    this.overrideDocForm.get('fname').setValue('');
    this.overrideDocForm.get('lname').setValue('');
    this.overrideDocForm.get('fullName').setValue('');
    this.overrideDocForm.get('sn').setValue('');
    this.overrideDocForm.get('dob').setValue('');
    this.overrideDocForm.get('issue').setValue('');
    this.overrideDocForm.get('expary').setValue('');
    this.overrideDocForm.get('docname').setValue('');
    this.overrideDocForm.get('address').setValue('');
    this.overrideDocForm.get('score').setValue('');
  }

  openDialogue(template: TemplateRef<any>){
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' })
  }

  openDialogueVerify(template: TemplateRef<any>){
    this.bsModalRef2 = this.modalService.show(template, { class: 'modal-dialog-centered' })
  }

  getFiles(event){
    this.getFaceFile.push(event.target.files[0])    
  }

  postUpload(){
    this.bsModalRef.hide();
    const body = new FormData();
    body.append('document', this.getFaceFile[0]);
    body.append('docCheckId', this.item.checkId);
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.postUploadFace(body, this.clientId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.dataService.toastrShow(`Face Uploaded Successfully`, 'success');
      this.faceS3Url = res.data;
      this.enabledUpdateButton = true;
    })
  }

  updateFace(){
    this.isChange = false
    const payload = {
      checkId : this.item.checkId,
      faceS3Url : this.faceS3Url
    }
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.postUpdateFace(payload, this.clientId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.changeFunction();
      this.dataService.toastrShow(`Face Updated Successfully`, 'success');
      this.enabledUpdateButton = false;
    })
  }

  overrideDocExtraction(){
    this.isChange = false
    this.bsModalRef.hide();
    if(this.item.docType === 'ADDRESS'){
      const payload = {
        extractedFace:null,
        identityExtractionRes: null,
        addressExtractionRes : {
          name:this.overrideDocForm.value.fullName,
          documentName:this.overrideDocForm.value.docname,
          address:this.overrideDocForm.value.address
        }
      }
      this.dataService.changeLoaderVisibility(true);
      this.devOpsService.overrideDocExtraction(this.clientId, this.item.checkId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(()=>{
        this.dataService.toastrShow(`Document Overrided`, 'success');
        this.changeFunction();
      })
    }
    else if(this.item.docType === 'IDENTITY'){
      const payload = {
        extractedFace:null,
        identityExtractionRes : {
            name:this.overrideDocForm.value.fullName,
            sn:this.overrideDocForm.value.sn,
            dob:this.overrideDocForm.value.dob,
            issuedate:this.overrideDocForm.value.issue,
            expirydate:this.overrideDocForm.value.expary,
            fname:this.overrideDocForm.value.fname,
            lname:this.overrideDocForm.value.lname
  
        },
        addressExtractionRes:null,
      }
      this.dataService.changeLoaderVisibility(true);
      this.devOpsService.overrideDocExtraction(this.clientId, this.item.checkId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(()=>{
        this.dataService.toastrShow(`Document Overrided`, 'success');
        this.changeFunction();
      })
    }
  }

  overrideDocVerify(){
    this.isChange = false
    this.bsModalRef.hide();
    let payload = {
      "name":this.overrideDocForm.value.fullName,
      "documentName": this.overrideDocForm.value.docname,
      "idate": this.overrideDocForm.value.issue,
      "edate": this.overrideDocForm.value.expary,
      "address": this.overrideDocForm.value.address,
      "sn": this.overrideDocForm.value.sn,
      "dob": this.overrideDocForm.value.dob,
      "fname": this.overrideDocForm.value.fname,
      "lname": this.overrideDocForm.value.lname,
      "score": this.overrideDocForm.value.score,
    }
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.overrideVerify(this.clientId, this.item.checkId, payload, this.overrideDocForm.value.status).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(()=>{
      this.dataService.toastrShow(`Verification Overrided`, 'success');
      this.changeFunction();
    })
  }

  editOverride(editType){
    this.isChange = false
    if(editType === 'name'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "name",
            "value": this.individualFrom.value.name!=""?this.individualFrom.value.name:null
        }
    }else if(editType === 'sn'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "sn",
            "value": this.individualFrom.value.sn!=""?this.individualFrom.value.sn:null
        }
    }else if(editType === 'dob'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "dob",
            "value": this.individualFrom.value.dob!=""?this.individualFrom.value.dob:null
        }
    } else if(editType === 'docname'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "documentname",
            "value": this.individualFrom.value.docname!=""?this.individualFrom.value.docname:null
        }
    } else if(editType === 'issue'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "issuedate",
            "value": this.individualFrom.value.issue!=""?this.individualFrom.value.issue:null
        }
    } else if(editType === 'address'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "address",
            "value": this.individualFrom.value.address!=""?this.individualFrom.value.address:null
        }
    } else if(editType === 'expary'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "expirydate",
            "value": this.individualFrom.value.expary!=""?this.individualFrom.value.expary:null
        }
    } 
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.editOption(this.clientId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() =>{
      let popup = {
        message : editType + " " + "updated successfully",
        status: 'success'
      }
      this.dataService.toastrShow(popup.message, popup.status);
      this.changeFunction()
    })
  }

  okOverride(editType){
    this.isChange = false
    if(editType === 'name'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "name",
        }
    }else if(editType === 'sn'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "sn",
        }
    }else if(editType === 'dob'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "dob",
        }
    } else if(editType === 'docname'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "documentname",
        }
    } else if(editType === 'issue'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "issuedate",
        }
    } else if(editType === 'address'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "address",
        }
    } else if(editType === 'expary'){
        var payload = {
            "checkId" : this.item.checkId,
            "key" : "expirydate",
        }
    } 
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.okOption(this.clientId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() =>{
      let popup = {
        message : editType + " " + "verified successfully",
        status: 'success'
      }
      this.dataService.toastrShow(popup.message, popup.status);
      this.changeFunction();
    })
}

  overrideLivenessExtraction(){
    this.bsModalRef.hide();
    let payload;
    payload = {
      verificationType: "LIVENESS",
      livenessRes: this.overrideLiveness.map((e) => ({ ...e, status: e?.status ? 'true' : 'false' }))
    }
    this.devOpsService.livenessOverride(this.item.checkId, this.clientId, payload, this.isLivenessVerified).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(()=>{
      this.dataService.toastrShow(`LIVENESS Overrided`, 'success');
      this.changeFunction();
    })
  }

  overrideSelfieExtraction(){
    this.bsModalRef.hide();
    let payload;
    payload = {
      verificationType: "SELFIE",
      azureFaceVerifyRes: {
        confidence: Number(this.selfieOverride?.confidence),
        isIdentical: this.selfieOverride?.isIdentical ? 'true' : 'false'
      }
    }
    this.devOpsService.selfieOverride(this.item.checkId, this.clientId, payload, this.selfieOverride.isVerified).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(()=>{
      this.dataService.toastrShow(`SELFIE Overrided`, 'success');
      this.changeFunction();
    })
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }

}
