import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { LegalOnboardingService } from '../../pages/legal-onboarding-2.0/services/legal-onboarding.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterAsDialogueComponent } from '../toaster-as-dialogue/toaster-as-dialogue.component';

@Component({
  selector: 'app-new-kyc-modal',
  templateUrl: './new-kyc-modal.component.html',
  styleUrls: ['./new-kyc-modal.component.scss']
})
export class NewKycModalComponent implements OnInit {
  currentTheme: string = localStorage.getItem("currentTheme");
  public onClose: Subject<any> = new Subject();
  type: 
    | "officer-view-edit-page"
    | "fetch-officer"
    | "add-officer"
    | "onboard-add-officers"
    | "crr-break-points"
    | "trade-break-points"
    | "show-warning"
    | "show-included-checks"
    | "trigger-workflow"
    | "cm-entityDetails"
    | "addedEntitiesStatus"
    | 'trade-risk-details'
    | "mark-as-false-positive"
    | "upcoming-review-dates"
    | "onboard-add-beneficial-owners"
    | "corporate-percentage-exceeded"
    | "corporate-percentage"

  fetchOfficerList: any[] = [];
  crrGuidDetails: any[] = [];
  tradeDetails:any[];
  warningData: any[];
  includedChecksData:any[] = [];
  companyName: any;
  maritalStatusArray: any = [
    { key: 'Married' },
    { key: 'Unmarried' },
    { key: 'Divorced' },
    { key: 'Widowed' },
  ];
  emailOptions: any = [
    { key: 'Entity SPOC'},
    { key: 'UBO Email' },
  ];
  countries: any[] = [];
  isUSCountry: boolean = false;
  viewType: any = '';
  accountId: any;
  infomationUpdated: boolean = false;
  generatedAPICalls: boolean = false;
  retailSubmitted: boolean = false;
  asNewSubmitted: boolean = false;
  isaddOnboardSubmitted: boolean = false;
  isOfficerEdit: boolean = false;
  partiesList: any[] = [];
  entityObject: any;
  tradeRiskData:any;
  uocmingReviewDateList: any[] = [];
  isaddOnboardUboSubmitted: boolean = false;
  officerEntityTypeArray = [
    { heading: 'INDIVIDUAL', slug: 'INDIVIDUAL' },
    { heading: 'CORPORATE', slug: 'INDIVIDUAL CORPORATE' },
  ];
  officerTypeArray: any[] = [
    { name: 'UBO' },
    { name: 'DIRECTOR' },
  ];
  officersList: any[] = [];
  isDirector: boolean = false;
  isCompanyAdd: boolean = false;
  currentStatusInd: any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Inactive', value: 'Inactive'},
  ];

  constructor(public bsModalRef: BsModalRef, private _app: AppService, private formBuilder : FormBuilder,
    private dataService: DataService, private legalServices: LegalOnboardingService, private modalService: BsModalService) { 
      //onboard-add-beneficial-owners
      this.addOnboardBeneficialOwners.get('controlOfficerEntityType').valueChanges.subscribe(
        val => {
          if(val === 'INDIVIDUAL CORPORATE'){
            this.addOnboardBeneficialOwners.controls['companyName'].setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)]);
            this.addOnboardBeneficialOwners.controls['companyStack'].setValidators([Validators.required]);
          }else{
            this.addOnboardBeneficialOwners.controls['companyName'].clearValidators();
            this.addOnboardBeneficialOwners.controls['companyStack'].clearValidators();
            this.addOnboardBeneficialOwners.controls['companyName'].setValue(null);
            this.addOnboardBeneficialOwners.controls['companyStack'].setValue(null);
          }
          this.addOnboardBeneficialOwners.controls['companyName'].updateValueAndValidity();
          this.addOnboardBeneficialOwners.controls['companyStack'].updateValueAndValidity();
        }
      );
      //add-officer

      this.addOfficerAsNew.get('officerType').valueChanges.subscribe(data => {
        if(data === 'UBO'){
          this.addOfficerAsNew.controls['controlOfficerEntityType'].setValidators([Validators.required]);
        }
        else if(data === 'DIRECTOR'){
          this.addOfficerAsNew.controls['controlOfficerEntityType'].clearValidators();
          this.addOfficerAsNew.controls['companyName'].clearValidators();
          this.addOfficerAsNew.controls['companyStack'].clearValidators();
          this.addOfficerAsNew.controls['sharePercentage'].clearValidators();
          this.addOfficerAsNew.controls['companyName'].setValue(null);
          this.addOfficerAsNew.controls['companyStack'].setValue(null);
          this.addOfficerAsNew.controls['sharePercentage'].setValue(null);
          this.addOfficerAsNew.controls['controlOfficerEntityType'].setValue('');
        }
        this.addOfficerAsNew.controls['controlOfficerEntityType'].updateValueAndValidity();
        this.addOfficerAsNew.controls['companyName'].updateValueAndValidity();
        this.addOfficerAsNew.controls['companyStack'].updateValueAndValidity();
        this.addOfficerAsNew.controls['sharePercentage'].updateValueAndValidity();
      })

      this.addOfficerAsNew.get('controlOfficerEntityType').valueChanges.subscribe(data => {
        if(data === 'INDIVIDUAL CORPORATE'){
          this.addOfficerAsNew.controls['companyName'].setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)]);
          this.addOfficerAsNew.controls['companyStack'].setValidators([Validators.required]);
        }else{
          this.addOfficerAsNew.controls['companyName'].clearValidators();
          this.addOfficerAsNew.controls['companyStack'].clearValidators();
          this.addOfficerAsNew.controls['companyName'].setValue(null);
          this.addOfficerAsNew.controls['companyStack'].setValue(null);
        }
        this.addOfficerAsNew.controls['companyName'].updateValueAndValidity();
        this.addOfficerAsNew.controls['companyStack'].updateValueAndValidity();
      })

      this.addOfficerAsNew.get('emailOptions').valueChanges.subscribe(data => {
        if(data === 'UBO Email'){
          this.addOfficerAsNew.controls['email'].setValidators([Validators.required, Validators.email]);
        }
        else{
          this.addOfficerAsNew.controls['email'].clearValidators();
          this.addOfficerAsNew.controls['email'].setValue(null);
        }
        this.addOfficerAsNew.controls['email'].updateValueAndValidity();
      })

    }

  addMoreDetailsForm: FormGroup = this.formBuilder.group({
    lastName: ['', Validators.pattern(/^[a-zA-Z ]+$/)],
    dob: [null],
    maritalStatus: [''],
    phoneNo: ['', Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{4,}$/ )],
    address: [''],
    ssn: [''],
    taxID: [''],
    percentage: [''],
    status: [''],
    designation: [''],
    firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
    email: ['', Validators.email],
    citizenship: ['', Validators.required],
    officerType: [''],
    controlOfficerEntityType: [''],
    sharePercentage: [''],
  });
  addFromRetail: FormGroup = this.formBuilder.group({
    officerType: ['', Validators.required],
    customerId: ['', Validators.required],
  });
  addOfficerAsNew: FormGroup = this.formBuilder.group({
    lastName: [null, Validators.pattern(/^[a-zA-Z ]+$/)],
    dob: [null],
    maritalStatus: [''],
    phoneNo: [null, Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{4,}$/ )],
    address: [null],
    ssn: [null],
    taxID: [null],
    percentage: [null],
    officerType: ['', Validators.required],
    status: [null],
    designation: [null],
    firstName: [null, [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
    sharePercentage: [null],
    citizenship: ['', Validators.required],
    controlOfficerEntityType: [''],
    emailOptions:['Entity SPOC', Validators.required],
    email: [null],
    companyName: [null],
    companyStack: [null],
  });
  addOnboardOfficers: FormGroup = this.formBuilder.group({
    firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
    email: ['', [Validators.email]],
    citizenshipCountryCode: ['', Validators.required],
  });
  addOnboardBeneficialOwners: FormGroup = this.formBuilder.group({
    firstName: [null, [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
    sharePercentage: [null, Validators.required],
    citizenshipCountryCode: ['', Validators.required],
    controlOfficerEntityType: ['', Validators.required],
    email: [null, [Validators.email]],
    companyName: [null],
    companyStack: [null],
  });
  officerItem: any;
  officerName: any = '';

  ngOnInit(): void {
    this.onClose.next('');
    if(this.viewType == 'perform-kyc'){
      this.addMoreDetailsForm.enable()
      this.addMoreDetailsForm.get('firstName').disable();
      this.addMoreDetailsForm.get('controlOfficerEntityType').disable();
      this.addMoreDetailsForm.get('email').disable();
    }
    else if(this.viewType == 'onboard-edit'){
      this.addMoreDetailsForm.enable()
      if(this.officerItem?.tpe){
        this.addMoreDetailsForm.get('firstName').disable();
      }
      this.addMoreDetailsForm.get('controlOfficerEntityType').disable();
    }
    else if(this.type == 'add-officer'){
      this.addMoreDetailsForm.enable()
    }
    else{
      this.addMoreDetailsForm.disable();
    }
    if(this.isCompanyAdd){
      this.addOfficerAsNew.get('officerType').setValue('UBO')
    }
    this._app.currentTheme.subscribe((theme) => {
      this.currentTheme = theme;
    });

    for(let i=0;i<document.getElementsByClassName('modal-content').length;i++) {
      var themeCall = document.getElementsByClassName('modal-content')[i]
      if(themeCall){
        if(i%2 != 0){
          themeCall.classList.add('modal-box-shadow');
        }
        themeCall.classList.add(this.currentTheme ? this.currentTheme : 'theme-dark');
      }
    }
    this._app.getAllCountries().subscribe((res) => {this.countries = res['data'] || []});
    this.patchValues();
    if(this.type == 'crr-break-points'){
      this.getCrrBreakPoints();
    }
  }

  patchValues(){
    const firstName = this.officerItem?.firstName || '';
    const lastName = this.officerItem?.lastName || '';
    this.officerName = firstName + ' ' + lastName;
    if(this.officerItem != null){
      const res = this.officerItem;
      this.onCountrySelect(res.citizenshipCountry?.countryCode);
      res?.dob ? this.addMoreDetailsForm.controls.dob.setValue(formatDate(res?.dob, 'yyyy-MM-dd', 'en-US')) : '';
      this.addMoreDetailsForm.patchValue({
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        maritalStatus: res?.maritalStatus || '',
        citizenship: res?.citizenshipCountry?.countryCode,
        address: res.residenceAddress,
        ssn: res.ssn,
        taxID: res.taxId,
        percentage: res.percentageOfOwnership,
        status: res.currentStatus,
        designation: res.designation,
        phoneNo: res.phoneNumber,
        controlOfficerEntityType: res?.controlOfficerEntityType,
        sharePercentage: res?.sharePercentage
      });
    }
  }

  onDateInputKeydown(event: KeyboardEvent) {
    event.preventDefault(); // Prevent any key input
  }

  getCrrBreakPoints(){
    var staticQuestionnaire = {
      key: "Questionnaire",
      score: "12.00",
      weight: "8",
      riskUnitRes: [
        {
          key: "Questionnaire",
          score: "1",
          weight: "8"
        }
      ]
    }
    if(this.crrGuidDetails?.length){
      for(let item of this.crrGuidDetails){
        if(item?.child?.length){
          for(let guid of item?.child){
            this.legalServices.CrrSearch(guid?.guid).subscribe((data) => {
              if(data && data['content'] && data['content']?.length){
                if(data['content'][0]?.riskProfile && data['content'][0]?.riskProfile?.riskFactor && data['content'][0]?.riskProfile?.riskFactor?.length){
                  for(let risk of data['content'][0]?.riskProfile?.riskFactor){
                    guid?.value.push(risk);
                  }
                }
              }
            })
          }
          // Added Static value of Questionnaire for Individual only
          if(item?.type == 'DIRECTOR' || item?.type == 'UBO'){
            for(let guid of item?.child){
              guid?.value.push(staticQuestionnaire);
            }
          }
        }
      }
    }
    
  }

  onCountrySelect(event) {
    if (event === 'US') {
      this.isUSCountry = true
    }
    else {
      this.isUSCountry = false;
    }
  }

  performKyc(type: string){
    if(type == 'perform-kyc'){
      const payload = {
        firstName: this.officerItem.firstName,
        // firstName: this.addMoreDetailsForm.value.firstName,
        lastName: this.addMoreDetailsForm.value.lastName,
        maritalStatus: this.addMoreDetailsForm.value.maritalStatus,
        dob: this.addMoreDetailsForm.value.dob,
        phoneNumber: this.addMoreDetailsForm.value.phoneNo,
        email: this.officerItem.email,
        // email: this.addMoreDetailsForm.value.email,
        residenceAddress: this.addMoreDetailsForm.value.address,
        ssn: this.addMoreDetailsForm.value.ssn,
        taxId: this.addMoreDetailsForm.value.taxID,
        percentageOfOwnership: this.addMoreDetailsForm.value.percentage,
        currentStatus: this.addMoreDetailsForm.value.status,
        designation: this.addMoreDetailsForm.value.designation,
        controlOfficerType: this.officerItem && this.officerItem.controlOfficerType,
        citizenshipCountryCode: this.addMoreDetailsForm.value.citizenship,
        controlOfficerEntityType: this.officerItem?.controlOfficerEntityType,
        sharePercentage: Number(this.addMoreDetailsForm.value.sharePercentage),
        id: this.officerItem?.id
      };
      this.isOfficerEdit = true;
      if(this.addMoreDetailsForm.valid){
        this.dataService.changeLoaderVisibility(true);
        this.legalServices.saveControlOfficer(this.accountId, payload).subscribe((res) => {
          this.infomationUpdated = true;
          this.legalServices.performKYC(this.officerItem.id).pipe(finalize(() => this.dataService.changeLoaderVisibility(false)))
            .subscribe(() => {
            this.bsModalRef.hide();
            this.onClose.next('back');
              const initialState = {
                imageUrl: '/assets/images/toaster/event-success.svg',
                subText: 'KYC Initiated, remote link has been sent!',
                type: 'success',
                button: 'Awesome!'
              }
              this.modalService.show(ToasterAsDialogueComponent, { initialState, 
                class: 'modal-dialog-centered toaster-modal'});
            });
          if (res['data'] && res['data'].tpe) {
            const message = {
              "message": "Officer's Information have been updated",
              "page": "Officer Details Update in user provided info",
              "geoLocation": null
            }
            this.legalServices.addActivityLog(res['data'].tpe.kysNo, message).subscribe();
          }
        });
      }
    }
    else if(type == 'edit'){
      this.addMoreDetailsForm.enable();
      this.addMoreDetailsForm.get('controlOfficerEntityType').disable();
      if(this.officerItem?.tpe){
        this.addMoreDetailsForm.get('firstName').disable();
      }
      this.viewType = 'edit-details';
    }
    else if(type == 'done'){
      this.bsModalRef.hide();
      this.onClose.next('back');
    }
    else if(type == 'discard'){
      this.addMoreDetailsForm.disable();
      this.viewType = 'view';
      this.patchValues();
    }
    else if(type == 'save-details'){
      const payload = {
        // firstName: this.officerItem.firstName,
        firstName: this.officerItem?.tpe ? this.officerItem.firstName : this.addMoreDetailsForm.value.firstName,
        lastName: this.addMoreDetailsForm.value.lastName,
        maritalStatus: this.addMoreDetailsForm.value.maritalStatus,
        dob: this.addMoreDetailsForm.value.dob,
        phoneNumber: this.addMoreDetailsForm.value.phoneNo,
        // email: this.officerItem.email,
        email: this.addMoreDetailsForm.value.email == '' ? null : this.addMoreDetailsForm.value.email,
        residenceAddress: this.addMoreDetailsForm.value.address,
        ssn: this.addMoreDetailsForm.value.ssn,
        taxId: this.addMoreDetailsForm.value.taxID,
        percentageOfOwnership: this.addMoreDetailsForm.value.percentage,
        currentStatus: this.addMoreDetailsForm.value.status,
        designation: this.addMoreDetailsForm.value.designation,
        controlOfficerType: this.officerItem && this.officerItem.controlOfficerType,
        citizenshipCountryCode: this.addMoreDetailsForm.value.citizenship,
        controlOfficerEntityType: this.officerItem?.controlOfficerEntityType,
        sharePercentage: Number(this.addMoreDetailsForm.value.sharePercentage),
        id: this.officerItem?.id
      };
      this.isOfficerEdit = true;
      if(this.addMoreDetailsForm.valid){
      this.dataService.changeLoaderVisibility(true);
      this.legalServices.saveControlOfficer(this.accountId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.infomationUpdated = true;
      this.addMoreDetailsForm.disable();
      this.viewType = 'view';
      if (res['data'] && res['data'].tpe) {
        const message = {
          "message": "Officer's Information have been updated",
          "page": "Officer Details Update in user provided info",
          "geoLocation": null
        }
        this.legalServices.addActivityLog(res['data'].tpe.kysNo, message).subscribe();
      }
    },
    );
    }
    }
    else if(type == 'onboard-edit'){
      this.isOfficerEdit = true;
      if(this.addMoreDetailsForm.valid){
      this.bsModalRef.hide();
      const requestBody = {
        firstName: this.addMoreDetailsForm.value.firstName,
        lastName: this.addMoreDetailsForm.value.lastName,
        maritalStatus: this.addMoreDetailsForm.value.maritalStatus,
        dob: this.addMoreDetailsForm.value.dob,
        phoneNumber: this.addMoreDetailsForm.value.phoneNo,
        email: this.addMoreDetailsForm.value.email,
        residenceAddress: this.addMoreDetailsForm.value.address,
        ssn: this.addMoreDetailsForm.value.ssn,
        taxId: this.addMoreDetailsForm.value.taxID,
        percentageOfOwnership: this.addMoreDetailsForm.value.percentage,
        currentStatus: this.addMoreDetailsForm.value.status,
        designation: this.addMoreDetailsForm.value.designation,
        controlOfficerType: this.officerItem && this.officerItem.controlOfficerType,
        citizenshipCountryCode: this.addMoreDetailsForm.value.citizenship,
        controlOfficerEntityType: this.officerItem?.controlOfficerEntityType,
        sharePercentage: Number(this.addMoreDetailsForm.value.sharePercentage),
        id: this.officerItem?.id
      };
      const data = {
        payload: requestBody,
        id: this.officerItem?.id
      }
      this.onClose.next(data);
    }
    }
  }

  get officerEdit(): { [key: string]: AbstractControl } {
    return this.addMoreDetailsForm.controls;
  }

  generateOfficers(){
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.generateCorporateUbo(this.accountId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.legalServices.fetchOfficerList(this.accountId).subscribe((res) => {
        this.fetchOfficerList = res || [];
        this.generatedAPICalls = true;
      });
    });
  }

  get retail(): { [key: string]: AbstractControl } {
    return this.addFromRetail.controls;
  }
  get asNew(): { [key: string]: AbstractControl } {
    return this.addOfficerAsNew.controls;
  }

  addOfficers(addType: string){
    if(addType == 'add-new'){
      this.asNewSubmitted = true;
      if(this.addOfficerAsNew.valid){
        this.bsModalRef.hide();
        const payload = {
          firstName: this.addOfficerAsNew.value.firstName,
          lastName: this.addOfficerAsNew.value.lastName,
          maritalStatus: this.addOfficerAsNew.value.maritalStatus,
          dob: this.addOfficerAsNew.value.dob,
          phoneNumber: this.addOfficerAsNew.value.phoneNo,
          email: this.addOfficerAsNew.value.email,
          residenceAddress: this.addOfficerAsNew.value.address,
          ssn: this.addOfficerAsNew.value.ssn,
          taxId: this.addOfficerAsNew.value.taxID,
          percentageOfOwnership: this.addOfficerAsNew.value.percentage,
          currentStatus: this.addOfficerAsNew.value.status,
          designation: this.addOfficerAsNew.value.designation,
          controlOfficerType: this.addOfficerAsNew.value.officerType,
          citizenshipCountryCode: this.addOfficerAsNew.value.citizenship,
          companyName: this.addOfficerAsNew.value.companyName,
          companySharePercentage: this.addOfficerAsNew.value.companyStack ? Number(this.addOfficerAsNew.value.companyStack) : null,
          controlOfficerEntityType: 'INDIVIDUAL',
          sharePercentage: Number(this.addOfficerAsNew.value.sharePercentage),
          type: 'added-officers'
        };
        this.onClose.next(payload);
      }
    }
    else if(addType == 'add-retail'){
      this.retailSubmitted = true;
      if (this.addFromRetail.valid) {
        this.bsModalRef.hide();
        const data = {
          id: this.addFromRetail.value.customerId,
          officerType: this.addFromRetail.value.officerType,
          type: 'from-retail'
        }
        this.onClose.next(data);
      }
    }
  }

  get onboardNewOfficers(): { [key: string]: AbstractControl } {
    return this.addOnboardOfficers.controls;
  }

  get onboardNewBeneficialOwners(): { [key: string]: AbstractControl } {
    return this.addOnboardBeneficialOwners.controls;
  }

  addOnboardedOfficers(type){
    if(type == 'add'){
      this.isaddOnboardSubmitted = true;
      if (this.addOnboardOfficers.valid) {
        this.bsModalRef.hide();
        this.onClose.next(this.addOnboardOfficers.value);
      }
    }
    else if(type == 'add-beneficial-owners'){
      this.isaddOnboardUboSubmitted = true;
      if (this.addOnboardBeneficialOwners.valid) {
        this.bsModalRef.hide();
        this.onClose.next(this.addOnboardBeneficialOwners.value);
      }
    }
  }

  expand(id,action){
    if(action=='expand'){
      var element = document.getElementById(id);
      element.classList.add('d-none');
      element.classList.remove('d-inline')
      var expelement=document.getElementById(id+'-expand')
      expelement.classList.add('d-inline')
      expelement.classList.remove('d-none')
    }
    else{
      var element = document.getElementById(id);
      element.classList.remove('d-none');
      element.classList.add('d-inline');
      var expelement=document.getElementById(id+'-expand');
      expelement.classList.remove('d-inline')
      expelement.classList.add('d-none')
    }
    
  }

  closeModel(){
    this.bsModalRef.hide();
  }

  equityJustify(){
    this.bsModalRef.hide();
    let message = '';
    if(this.officersList.length){
      for(let item of this.officersList){
        if(item.totalSharePercentage < 100){
          message += ' ' + item.equityJustificationMsg;
        }
      }
    }
    this.onClose.next(message);
  }
  
  disableEquityButton(){
    if(this.officersList.length){
      if(this.officersList.every((f) => {
        if(f.totalSharePercentage < 100){
          if(f.equityJustificationMsg){
            return true
          }
          else{
            return false
          }
        }
        else{
          return true
        }
      })){
        return false
      }
      else{
        return true
      }
    }
  }
    
  editStakes(){
    let officerList = [];
    for(let item of this.officersList){
      for(let officer of item.officerList){
        let obj = { officerId: officer.id, sharePercentage: Number(officer.sharePercentage) };
        officerList.push(obj);
      }
      for(let company of item.companiesList){
        for(let id of company.stackOwnerIds){
          let obj = { officerId: id, companySharePercentage: Number(company.stack) };
          officerList.push(obj);
        }
      }
    }
    const payload = {
      officers: officerList
    }
    this.dataService.changeLoaderVisibility(true);
    this._app.editStakes(this.accountId, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow("Details have been updated", `success`);
      this.bsModalRef.hide();
      this.onClose.next('');
    })
  }

}
