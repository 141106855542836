import { Component, Input, OnInit } from '@angular/core';
import { NewKycModalComponent } from '../../../app/shared/new-kyc-modal/new-kyc-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-new-aml-summary',
  templateUrl: './new-aml-summary.component.html',
  styleUrls: ['./new-aml-summary.component.scss']
})
export class NewAmlSummaryComponent implements OnInit {
  @Input() amlSummary: any;
  @Input() amlType: any;
  showHideDetailsArray: any[] = [];
  isSalesRole: boolean = false;
  public bsModalRef: BsModalRef;

  constructor(private modelService: BsModalService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem("salesRole") == 'ROLE_CLIENTUSER2'){
      this.isSalesRole = true;
    }
    else{
      this.isSalesRole = false;
    }
  }

  toggleDetails(index){
    const i = this.showHideDetailsArray.indexOf(index);
    if(i > -1){
      this.showHideDetailsArray.splice(i, 1);
    }
    else{
      this.showHideDetailsArray.push(index);
    }
  }

  markAsFalsePositive(){
    const initialState = {
      type: "mark-as-false-positive",
    }
    this.bsModalRef = this.modelService.show(NewKycModalComponent, { initialState, class: 'modal-lg new-modal-as-dialogue' });
  }

}
