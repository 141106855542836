import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShowImageModule } from "../../directives/show-image/show-image.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxPaginationModule } from 'ngx-pagination';
import {
  RiskPolicyComponent,
  NewAmlSummaryComponent,
  IdVerificationComponent,
  AmlSummaryDetailsComponent,
  AllDocUploadComponent,
  SelfieVerificationComponent,
  LivelinessVerificationComponent,
  QuestionaireComponent,
  WidgetsComponent,
  TriggerWorkFlowComponent,
  TriggerWorkFlowScreenTwoComponent,
  TriggerWorkFlowScreenThreeComponent,
  EntityDetailsPageComponent,
} from '../index';
import { AddressVerificationComponent } from '../address-verification/address-verification.component'
import { RelationGraphComponent } from '../relation-graph/relation-graph.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { StaticDocsDataComponent } from '../static-docs-data/static-docs-data.component';

@NgModule({
  declarations: [
    RiskPolicyComponent,
    NewAmlSummaryComponent,
    IdVerificationComponent,
    AddressVerificationComponent,
    AmlSummaryDetailsComponent,
    AllDocUploadComponent,
    SelfieVerificationComponent,
    LivelinessVerificationComponent,
    QuestionaireComponent,
    WidgetsComponent,
    TriggerWorkFlowComponent,
    TriggerWorkFlowScreenTwoComponent,
    TriggerWorkFlowScreenThreeComponent,
    EntityDetailsPageComponent,
    RelationGraphComponent,
    StaticDocsDataComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ShowImageModule,
    NgxPaginationModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgxGraphModule
  ],
  exports: [
    RiskPolicyComponent,
    NewAmlSummaryComponent,
    IdVerificationComponent,
    AddressVerificationComponent,
    AmlSummaryDetailsComponent,
    AllDocUploadComponent,
    SelfieVerificationComponent,
    LivelinessVerificationComponent,
    QuestionaireComponent,
    WidgetsComponent,
    TriggerWorkFlowComponent,
    TriggerWorkFlowScreenTwoComponent,
    TriggerWorkFlowScreenThreeComponent,
    EntityDetailsPageComponent,
    RelationGraphComponent,
    StaticDocsDataComponent
  ],
})
export class ComponentModuleModule { }
