import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LegalOnboardingService } from '../../../app/pages/legal-onboarding-2.0/services/legal-onboarding.service';
import { AppService } from '../../../app/services/app.service';
import { DataService } from '../../../app/services/data.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-kyc-charts-modal',
  templateUrl: './kyc-charts-modal.component.html',
  styleUrls: ['./kyc-charts-modal.component.scss']
})
export class KycChartsModalComponent implements OnInit {
  currentTheme: string = localStorage.getItem("currentTheme");
  public onClose: Subject<any> = new Subject();
  type: 
  | "insights-settings"
  | "insights-fullscreen"
  | "customize-insights"

  countries: any;
  selectedChartToEdit:any;
  selectedChartTypeToEdit: any;

  selectedChartForDetailedView:any;

  chartOf:string="";
  chartArr:any;

  constructor(public bsModalRef: BsModalRef, private _app: AppService, private formBuilder : FormBuilder,
    private dataService: DataService, private legalServices: LegalOnboardingService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this._app.currentTheme.subscribe((theme) => {
      this.currentTheme = theme;
    });
    this.selectedChartTypeToEdit = this.selectedChartToEdit?.chartType
    for(let i=0;i<document.getElementsByClassName('modal-content').length;i++) {
      var themeCall = document.getElementsByClassName('modal-content')[i]
      if(themeCall){
        if(i%2 != 0){
          themeCall.classList.add('modal-box-shadow');
        }
        themeCall.classList.add(this.currentTheme ? this.currentTheme : 'theme-dark');
      }
    }
    this._app.getAllCountries().subscribe((res) => {this.countries = res['data'] || []});
  }

  handleChartTypeChange(chartType) {
    this.selectedChartTypeToEdit = chartType.target.value;
  }

  handleChartLabelColor(i, color) {
    if (this.selectedChartToEdit.chartType !== "line") {
      this.selectedChartToEdit.dataSet[i].data[0].color = color;
      console.log(this.selectedChartToEdit.dataSet[i])
    } else {
      this.selectedChartToEdit.dataSet[0].color = color;
    }
  }

  saveChartData(){
    const payload = {
      dataSet: this.selectedChartToEdit.dataSet,
      chartType:
        this.selectedChartTypeToEdit || this.selectedChartToEdit.chartType,
    }
    this.bsModalRef.hide();
    this.onClose.next(payload);
  }

  handleCustomizeWidgets(){
    this.bsModalRef.hide();
    this.onClose.next(this.chartArr);
  }

}
