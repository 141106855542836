import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class LayoutService {
  layoutMenu: any = [
    {
      slug: "home",
      title: "Home",
      router: "/home",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN", "ROLE_DEVOPS"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-home",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      children: [],
    },
    // {
    //   title: "Home",
    //   router: "/home/india",
    //   count: 0,
    //   role: ["admin"],
    //   customer:['india'],
    //   icon: "fa fa-home",
    //   children: []
    // },
    {
      slug: "user-home",
      title: "User Area",
      router: "/user-home",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-user",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      children: [],
    },
    {
      slug: "user-home",
      title: "Reports",
      router: null,
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-file",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      children: [
        {
          title: "Status",
          count: 0,
          router: "/new-ind-report",
          india: true,
        },
        {
          title: "User Submissions",
          count: 0,
          router: "/user-reports",
          india: true,
        },
        {
          title: "AML Alerts",
          count: 0,
          router: "/aml-alerts",
          india: false,
        },
        {
          title: "Expired Incomplete Report",
          count: 0,
          router: "/expired-checks",
          india: false,
        },
        {
          title: "Override Report",
          count: 0,
          router: "/override-report",
          india: false,
        },
      ],
    },
    // {
    //   title: "Analytics",
    //   router: "/analytics",
    //   count: 0,
    //   role: ["admin"],
    //   icon: "fa fa-line-chart",
    //   children : []
    // },
    {
      slug: "user-home",
      title: "AML Search",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international"],
      icon: "/assets/images/sidebar/AML_Check_Icon.svg", //"fa fa-search",
      actveIcon: "/assets/images/sidebar/AML_Check_Icon_Blue.svg",
      router: "/aml-search",
      children: [],
      details:
        "Begin with the basic details of your customer to perform an AML Search . Your search is saved in the AML Search List.",
    },
    // {
    //   slug:"user-home",
    //   title: "Profile Search",
    //   count: 0,
    //   role: "admin",
    //   customer:['international'],
    //   icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-search",
    //   router: "/profile-search",
    //   children: []
    // },
    {
      slug: "user-home",
      title: "Corporate Search",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international"],
      icon: "/assets/images/sidebar/Corporate_Check_Icon.svg", //"fa fa-building-o",
      actveIcon: "/assets/images/sidebar/Corporate_Check_Icon_Blue.svg",
      router: "/corporate-search",
      children: [],
    },
    {
      slug: "user-home",
      title: "Do Live Check",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-check",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      router: "/live-check",
      children: [],
    },
    {
      slug: "profile-search",
      title: "Profile Search",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/AML_Check_Icon.svg", //"fa fa-credit-card",
      actveIcon: "/assets/images/sidebar/AML_Check_Icon_Blue.svg",
      router: "/aml-screening",
      children: [],
      details:
        "Our AML Search solution screen against the sanction, global PEPs and crimes to stay compliant with the ever-changing AML regulations. Through our AML search solution you can obtain details like - Name, DOB ID (*e.g. Passport, Driving license)Adverse media results,Relationship results(*PEP),Sanctions and many more..",
    },
    {
      slug: "aml-check",
      title: "AML Check",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Corporate_Check_Icon.svg", //"fa fa-credit-card",
      actveIcon: "/assets/images/sidebar/Corporate_Check_Icon_Blue.svg",
      router: "/aml-check",
      children: [],
      details:
        "Begin with the basic details of your customer to perform an AML Search . Your search is saved in the AML Search List.",
    },
    {
      slug: "adverse-media-engine",
      title: "Adverse Media",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Corporate_Check_Icon.svg", //"fa fa-credit-card",
      actveIcon: "/assets/images/sidebar/Corporate_Check_Icon_Blue.svg",
      router: "/adverse-media-engine",
      children: [],
      details:
        "Begin with the basic details of your customer to perform an AML Search . Your search is saved in the AML Search List.",
    },
    {
      slug: "corporate-check",
      title: "Corporate Check",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Corporate_Check_Icon.svg", //"fa fa-credit-card",
      actveIcon: "/assets/images/sidebar/Corporate_Check_Icon_Blue.svg",
      router: "/corporate-check",
      children: [],
      details:
        "Begin with the basic details of your legal entity to perform an Corporate Check . Your search is saved in the AML Search List.Perform Corporate checks on Legal entities as well on their UBOs and Directors.",
    },
    // {
    //   title: "Aml Check",
    //   count: 0,
    //   role: ["admin","ROLE_SUPERADMIN"],
    //   customer:['india','international'],
    //   icon: "/assets/images/sidebar/Corporate_Check_Icon.svg", //"fa fa-credit-card",
    //   router: "/aml-check",
    //   children: [],
    //   details:'Begin with the basic details of your legal entity to perform an Corporate Check . Your search is saved in the AML Search List.Perform Corporate checks on Legal entities as well on their UBOs and Directors.',
    // },
    // {
    //   title: "Corporate Check",
    //   count: 0,
    //   role: ["admin","ROLE_SUPERADMIN"],
    //   customer:['india','international'],
    //   icon: "/assets/images/sidebar/Corporate_Check_Icon.svg", //"fa fa-credit-card",
    //   router: "/corporate-check",
    //   children: [],
    //   details:'Begin with the basic details of your legal entity to perform an Corporate Check . Your search is saved in the AML Search List.Perform Corporate checks on Legal entities as well on their UBOs and Directors.',
    // },
    {
      slug: "retail-onboarding",
      title: "Retail Onboarding",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Retail_Onboarding_Icon.svg", //"fa fa-address-card",
      actveIcon: "/assets/images/sidebar/Retail_Onboarding_Icon_Blue.svg",
      // router: "/retail-onboarding", // For Version 1
      router: "/new-retail-onboarding", // For Version 2
      children: [],
      details:
        "Retail Onboarding allows the user to onboard an individual and perform various checks on him based on the Risk Policy Template to generate a Risk Report.",
    },
    {
      slug: "new-legal-onboarding",
      title: "Corporate Onboarding",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Legal_Entity_Onboarding.svg", //"fa fa-credit-card",
      actveIcon: "/assets/images/sidebar/Legal_Entity_Onboarding_Blue.svg",
      // router: "/new-legal-onboarding", // For Version 1 'legal-onboarding'
      children: [
        {
          title: "Onboarding",
          count: 0,
          router: "/new-legal-onboarding",
          india: true,
          slug: 'onboarding'
        },
        {
          title: "Risk Dashboard",
          count: 0,
          router: "/new-legal-onboarding/risk-dashboard",
          india: true,
          slug: 'risk-dashboard'
        }
      ],
      details:
        "Legal Entity Onboarding allows the user to onboard a Legal Entity and perform various checks on the entity based on the Risk Policy Template to generate a Risk Report.",
    },
    {
      slug: "ops-flow",
      title: "Ops Flow",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN", "ROLE_DEVOPS"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Ops_flow.svg", //"fa fa-adjust",
      actveIcon: "/assets/images/sidebar/Ops_flow_Blue.svg",
      router: "/ops-flow",
      children: [],
    },
    {
      slug: "dev-ops",
      title: "Dev Ops",
      count: 0,
      role: ["ROLE_SUPERADMIN", "ROLE_DEVOPS"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-adjust",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      router: "/dev-ops",
      children: [],
    },

    {
      slug: "",
      title: "API Upload",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-upload",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      router: "/api-upload",
      children: [],
      details:
        "API Upload helps user upload multiple customer files onto our Dashboard for onboarding and monitoring purposes .",
    },
    {
      slug: "digital-verification",
      title: "Digital Verification",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-check",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      router: "/digital-verification",
      children: [],
    },
    {
      slug: "risk-report",
      title: "Risk Report",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Risk_Report_Icon.svg", //"fa fa-book",
      actveIcon: "/assets/images/sidebar/Risk_Report_Icon_Blue.svg",
      router: "/risk-report",
      children: [],
      details:
        "Risk Report allows the user to generate and view Risk Ratings and Quick Risk Reports",
    },
    {
      slug:"aml-monitoring",
      title: "AML Monitoring",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["india", "international"],
      icon: "/assets/images/sidebar/Risk_Report_Icon.svg", //"fa fa-book",
      actveIcon: "/assets/images/sidebar/Risk_Report_Icon_Blue.svg",
      router: "/aml-monitoring",
      children: [],
      details:'AML Monitoring',
    },
    {
      slug: "bluk-upload",
      title: "Bulk Upload",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international"],
      icon: "/assets/images/sidebar/Bulk_Upload_Icon.svg", //"fa fa-upload",
      actveIcon: "/assets/images/sidebar/Bulk_Upload_Icon_Blue.svg",
      router: "/bulk-upload",
      children: [],
    },
    {
      slug: "doc-extraction",
      title: "Doc Extraction",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-recycle",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      router: "/doc-extraction",
      children: [],
    },
    {
      slug: "co-activity",
      title: "CO Activity",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-history",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      router: "/activity-log",
      children: [],
    },
    {
      slug: "settings",
      title: "Settings",
      router: null,
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international", "india"],
      icon: "/assets/images/sidebar/Setting_Icon.svg", //"fa fa-gears",
      actveIcon: "/assets/images/sidebar/Setting_Icon_Blue.svg",
      children: [
        {
          title: "General",
          count: 0,
          router: "/settings",
        },
        {
          title: "Customize",
          count: 0,
          router: "/customize",
        },
        {
          title: "Theming",
          count: 0,
          router: "/theming",
          access_slug: "theming",
        },
      ],
    },
    {
      slug: "billings",
      title: "Billing",
      router: null,
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international", "india"],
      icon: "/assets/images/sidebar/Biling_Icons.svg", //"fa fa-gbp",
      actveIcon: "/assets/images/sidebar/Biling_Icons_Blue.svg",
      children: [
        {
          title: "Pricing",
          count: 0,
          router: "/pricing",
        },
        {
          title: "Invoices",
          count: 0,
          router: "/invoices",
        },
        {
          title: "Coupons",
          count: 0,
          router: "/coupons",
        },
        {
          title: "Plan & Subscriptions",
          count: 0,
          router: "/subscriptions",
        },
      ],
    },
    {
      slug: "roles",
      title: "Roles",
      router: "/roles",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international"],
      icon: "/assets/images/sidebar/Roles_Icon.svg", //"fa fa-question-circle",
      actveIcon: "/assets/images/sidebar/Roles_Icon_Blue.svg",
      children: [],
    },
    {
      slug: "developer",
      title: "Developer",
      router: "/developer",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international", "india"],
      icon: "/assets/images/sidebar/Developer.svg", //"fa fa-code",
      actveIcon: "/assets/images/sidebar/Developer_Blue.svg",
      children: [],
    },
    {
      slug: "faq",
      title: "FAQ",
      router: "/faq",
      count: 0,
      role: ["admin", "ROLE_SUPERADMIN"],
      customer: ["international"],
      icon: "/assets/images/sidebar/Home_Icon.svg", //"fa fa-question-circle",
      actveIcon: "/assets/images/sidebar/Home_Icon_Blue.svg",
      children: [],
    },
  ];

  layoutMenuTiny: any = [
    "/home",
    "/user-reports",
    "/aml-reports",
    "check-status",
    "/history",
    "/live-check",
    "/doc-extraction",
    "/settings",
    "/doc-extraction",
    "/roles",
    "/developer",
    "/faq",
  ];

  constructor(
    private http: HttpClient,
    @Inject("API_URL") public API_URL: any
  ) {
    // this.layoutMenuTiny = this.layoutMenu.map((x)=>{
    //   return x.router;
    // });
  }
  getUserMenu() {
    return this.http
      .get(`${this.API_URL}/fe/clientsuser/all-pages`)
      .pipe(map((res: any) => res.data));
  }

  getLayout(role: string, usermenu = []) {
    var menus = [];
    if (usermenu.length > 0) {
      usermenu.forEach((item) => {
        let menu = this.layoutMenu.find((obj) => item.pageSlug == obj.slug);
        if (menu != undefined && menu != null) {
          let obj = { ...menu, children: [] }
          if(menu?.children?.length){
            for(let child of menu?.children){
              if(item?.childSlugs?.includes(child?.slug)){
                obj['children'].push(child)
              }
            }
          }
          menus.push(obj);
        }
      });
    } else {
      menus = this.layoutMenu;
    }
    return menus.filter((layout: any) => {
      return layout.role.includes(role);
    });
  }
}
