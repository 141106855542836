export interface IRequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  // params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: "arraybuffer" | "blob" | "json" | "text";
  withCredentials?: boolean;
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LegalOnboardingService {

  constructor(private http: HttpClient, @Inject('API_URL') public KYS_API_URL: any) { }

  getAllCountries() {
    return this.http.get<any>(`${this.KYS_API_URL}/country/all `).pipe(map((res) => res["data"]));
  }

  getCorporateOnboardList(page: number, size: number) {
    return this.http
      .post(`${this.KYS_API_URL}/kys/v2.0/tpe/corporate/onboarding`, {
        page: page,
        size: size,
        orderBy: null,
        order: 'DESC',
      })
      .pipe(map((res: any) => res["data"]));
  }

  corporateFilterBySearch(obj) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/tpe/corporate/onboarding/search`, obj).pipe(map((res: any) => res["data"]));
  }

  corporateFilterByStatus(obj) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/tpe/corporate/onboarding`, obj).pipe(map((res: any) => res["data"]));
  }

  getThirdPartyInfo(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/tp/progress/${id}`).pipe(map((res: any) => res["data"]));
  }

  getDocumentsInfo(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/document/info/${id}`).pipe(map((res: any) => res["data"]));
  }

  getOverallStatus(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/status/${id}`).pipe(map((res: any) => res["data"]));
  }

  getCorporateDetails(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/corporate/status/${id}`).pipe(map((res: any) => res["data"]));
  }

  getCorporateAccountInfo(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/accountinfo/corporate/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getManualUbo(accountId: any) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/list/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getAMLStatus(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/aml/${id}`).pipe(map((res: any) => res["data"]));
  }

  getAdverseMediaStatus(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/adverse-media/${id}`).pipe(map((res: any) => res["data"][0]));
  }

  saveControlOfficer(accountId, payload) {
    return this.http.put(`${this.KYS_API_URL}/kys/v2.0/co/update/${accountId}`, payload);
  }

  performKYC(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/start-kyc/${id}`);
  }

  generateCorporateUbo(accountId: string) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/account/corporate/generate/control-officer/${accountId}`, {}).pipe(map((res: any) => res["data"]));
  }

  linkOfficerToLegal(accountId, payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/co/link-officer/${accountId}`, payload);
  }

  getOverAllScore(accountId: string) {
    return this.http.get<number>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/generate-score/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getSystemUbo(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/corporate/control-officer/list/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  fetchOfficerList(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/corporate/fetch/control-officer/list/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getCheckList(accountId) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/check-list/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  deleteControlOfficers(officerId){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/delete/${officerId}`);
  }

  getAllCheck(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/all/check/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getSummary(userName: any) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/tpe/summary/${userName}`).pipe(map((res: any) => res["data"]));
  }

  getDocList(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/check/digital-upload/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getAllStates(id) {
    return this.http.get(`${this.KYS_API_URL}/provinces/country/${id}`).pipe(map((res: any) => res.data));
  }

  reverifyDocument(documentId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/check/extract-and-verify?kysDocumentCheckV1Id=${documentId}`);
  }

  getUserSpecificRiskTemplate(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/user/risk-policy/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getDocumentUrl(url: string) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/document/check/imageurl`, { s3url: url }).pipe(map((res: any) => res["data"]));
  }

  getVerifiedResult(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/verification-result?kysDocumentCheckV1Id=${id}`).pipe(map((res) => res["data"]));
  }

  getCompanyNewJurisdictions(country) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/jurisdiction/country/${country}`).pipe(map((res) => res["data"]));
  }

  dashboardConfiguration() {
    return this.http.get<any>(`${this.KYS_API_URL}/client/config/v1/dashboard-config`).pipe(map((res) => res["data"]));
  }

  riskPolicy(accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/risk-policy/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  createCorporateAccount(accountInfo: Object) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/account/corporate/register`, accountInfo).pipe(map((res: any) => res));
  }

  assignCorporateRiskTemplate(template: Object) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/account/corporate/risk-policy`, template);
  }

  getAllInfo(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/${id}`).pipe(map((res: any) => res["data"]))
  }

  corporateUpdateInfo(id, obj) {
    return this.http.put(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/${id}`, obj).pipe(map((res: any) => res["data"]))
  }

  getAllChecks(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/doc/check/${id}`).pipe(map((res: any) => res["data"]))
  }

  getAllProvince(id) {
    return this.http.get(`${this.KYS_API_URL}/provinces/country/${id}`).pipe(map((res: any) => res["data"]))
  }

  addOfficers(id, obj) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/co/add/${id}`, obj).pipe(map((res: any) => res["data"]));
  }

  getOfficersList(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/group/${id}`).pipe(map((res: any) => res["data"]))
  }

  getUboTreeList(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/v1/group/${id}?type=UBO`).pipe(map((res: any) => res["data"]))
  }

  updateOfficersInfo(id, obj) {
    return this.http.put(`${this.KYS_API_URL}/kys/v2.0/co/update/${id}`, obj).pipe(map((res: any) => res["data"]));
  }

  assignRiskforOfficers(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/control-officer-risk-policy/${id}`).pipe(map((res: any) => res["data"]))
  }

  getAllChecksforOfficers(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/control-officer-get-checks/${id}`).pipe(map((res: any) => res["data"]))
  }

  getDocName(country, type) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/available/docs/${country}?type=${type}`).pipe(map((res: any) => res["data"]))
  }

  resendRemoteLink(kysNo) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/remote/resend-link/${kysNo}`).pipe(map((res: any) => res["data"]))
  }

  legalDownloadPdf(accountId) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/download-report/${accountId}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }), responseType: 'blob'
    }).pipe(map((res: any) => res));
  }

  downloadPriodicReport(payload) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/account/export`, payload, {
      responseType: 'blob'
    }).pipe(map((res: any) => res));
  }

  corpDownloadReport(username) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
    });

    let authHttpOptions = {
      headers: headers,
      responseType: 'blob',
    };

    return this.http
      .get(`${this.KYS_API_URL}/kys/v2.0/co/download-document/${username}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream',
        }),
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  onboardClient(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/accept-button/${id}`).pipe(map((res) => res));
  }

  rejectClient(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/reject-button/${id}`).pipe(map((res) => res));
  }

  getIndividualInformationByKysNo(kysNo) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/individual/kysNo/${kysNo}`).pipe(map((res: any) => res["data"]));
  }

  getDevopsIndividualInformationByKysNo(clientId, kysNo) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/individual/devops/${clientId}/kysNo/${kysNo}`).pipe(map((res: any) => res["data"]));
  }

  corpSubmit(id, obj) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/submit/${id}`, obj).pipe(map((res: any) => res.data));
  }

  uboSubmit(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/control-officer/submit/${id}`).pipe(map((res: any) => res.data));
  }

  getAllCheckForUBO(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/officer/get-check/${id}`).pipe(map((res: any) => res.data));
  }

  getArticle(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/adverse-media/article/${id}?adverseMediaSourceType=DEVOPS`).pipe(map((res: any) => res.data));
  }

  getLegalRiskScore(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/corporate/get-risk-status-score/${id}`).pipe(map((res) => res['data']));
  }

  getRiskScreening(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/co/risk-screening/${id}`).pipe(map((res) => res['data']));
  }

  getSummaryData(id){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/co/summary/${id}`).pipe(map((res) => res['data']));
  }

  getLivelinessVideo(s3URL) {
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });
    let authHttpOptions = {
      headers: headers,
      responseType: "blob"
    };
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/get-video-doc?s3Url=${s3URL}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/octet-stream"
      }),
      responseType: "blob"
    }).pipe(map(res => {
      return res;//['data'];
    })
    );
  }

  getCompanyExtractedData(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/corporate/get-open-corp-data/${id}`).pipe(map((res: any) => res.data));
  }

  getCompanyExtractedDataNewApi(id) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/corporate/company-details/${id}`).pipe(map((res: any) => res.data));
  }

  addActivityLog(kysNo, payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/tpe-activity/add/${kysNo}`, payload);
  }

  fetchAllActivityLog(accountId, payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/tpe-activity/get/all-activity/${accountId}`, payload);
  }
  updateTemplate(payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v1/customised-template/edit`, payload);
  }
  getAccessList(id){
    
    return this.http.post(`${this.KYS_API_URL}/kys/v1/customised-template/get/${id}`, null);
  }
  getQuestionnaireDetails(id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/questions/check/cheks/${id}`)
  }

  archiveTheEntry(payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/tpe/update-record-status`, payload).pipe(map((res: any) => res['data']));
  }

  assignEntry(payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys-tpe/assign/assign-to-user`, payload).pipe(map((res: any) => res['data']));
  }

  getActiveClientUserList(){
    return this.http.get(`${this.KYS_API_URL}/clientsuser/activeusers`).pipe(map((res: any) => res['data']));
  }

  onboardedList(apiUrl: string, payload: Object){
    return this.http.post(`${this.KYS_API_URL}/${apiUrl}`, payload).pipe(map((res: any) => res['data']));
  }

  caseManagementList(payload: Object, apiUrl: String){
    return this.http.post(`${this.KYS_API_URL}/${apiUrl}`, payload).pipe(map((res: any) => res['data']));
  }

  newCaseGenerate(payload: Object, apiUrl){
    return this.http.post(`${this.KYS_API_URL}/${apiUrl}`, payload).pipe(map((res: any) => res['data']));
  }

  caseAssignEntry(payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kychub/kyb/case/assign-to-user`, payload).pipe(map((res: any) => res['data']));
  }

  caseArchiveTheEntry(payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kychub/kyb/case/archive`, payload).pipe(map((res: any) => res['data']));
  }

  getCaseDetailsById(id){
    return this.http.get(`${this.KYS_API_URL}/kychub/kyb/case/fetch/${id}`).pipe(map((res: any) => res['data']));
  }

  getCaseSummary(caseId: string){
    return this.http.get(`${this.KYS_API_URL}/kychub/kyb/case/summary/${caseId}`).pipe(map((res: any) => res?.data));
  }

  getCaseAddedEntities(caseId: string){
    return this.http.get(`${this.KYS_API_URL}/kychub/kyb/case/added-entity/${caseId}`).pipe(map((res: any) => res?.data));
  }

  getCaseAddedEntitiesRisk(caseId: string){
    return this.http.get(`${this.KYS_API_URL}/kychub/kyb/case/added-entity/risk/${caseId}`).pipe(map((res: any) => res?.data));
  }

  caseAcceptReject(payload){
    return this.http.post(`${this.KYS_API_URL}/kychub/kyb/case/accept-reject`, payload).pipe(map((res: any) => res['data']));
  }

  getCaseDecisionHistory(payload){
    return this.http.post(`${this.KYS_API_URL}/kychub/kyb/case/decision-history`,payload).pipe(map((res: any) => res?.data));
  }

  getCaseTradeRisk(caseId: string){
    return this.http.get(`${this.KYS_API_URL}/kychub/kyb/case/trade-cycle/${caseId}`).pipe(map((res: any) => res?.data));
  }

  riskDBFilterList(payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/risk-dashboard/filter`, payload).pipe(map((res: any) => res['data']));
  }

  downloadReportsInBulk(payload: Object) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
    });

    return this.http
      .post(`${this.KYS_API_URL}/kys/v2.0/co/download/reports`, payload, {
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  submitAnswers(kysNo, questionID, payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/questions/check/submit-answer/${kysNo}/${questionID}`, payload).pipe(map((res:any) => res.data));
  }

  uploadQuestionnaireDocs(questionID, payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/questions/check/upload-document/${questionID}`, payload).pipe(map((res:any) => res.data));
  }

  CrrSearch(guid){
    return this.http.post(`${this.KYS_API_URL}/v2/crr-data/search`, {
      guid: guid
    }).pipe(map((res:any) => res?.data));
  }

  getTradeData(kysNo){
    return this.http.get(`${this.KYS_API_URL}/kys/trade-check/get-by/${kysNo}`).pipe(map((res:any) => res?.data));
  }

  getCrrDataforTrade(payload){
    return this.http.post(`${this.KYS_API_URL}/kys/trade-check/all-entity/risk`,payload).pipe(map((res:any) => res?.data));
  }

  getCustomRecord(payload){
    return this.http.post(`${this.KYS_API_URL}/v1/customsRecord`,payload).pipe(map((res:any) => res?.data));
  }

  chatGptCompanySummary(payload){
    return this.http.post(`${this.KYS_API_URL}/kyc/ai-search-engine`,payload).pipe(map((res:any) => res?.data));
  }

  getChartsData(){ 
    return this.http
      .get<any>(
        `${this.KYS_API_URL}/kys/v1.0/widget/get-all`
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  pendingDecisionsList(data) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/tpe/aml-change`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  editChartsActiveStatus(payload){
    return this.http
    .post<any>(
      `${this.KYS_API_URL}/kys/v1.0/widget/on-off`,payload
    )
    .pipe(
      map((res) => {
        return res;
      })
    );
  }

  editChartData(id,payload){ 
    return this.http
      .post<any>(
        `${this.KYS_API_URL}/kys/v1.0/widget/edit/${id}`,payload
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getChartsWidgetsData(url){ 
    return this.http
      .get<any>(
        `${this.KYS_API_URL}/${url}`
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }
  
  getRiskDetailsData(id){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/risk-dashboard/get-by-id/${id}`).pipe(
      map((res) => {
        return res['data']
      })
    )
  }

  getAdverseMediaOfEntity(payload){
    return this.http.post<any>(`${this.KYS_API_URL}/adversemedia/get-articles`,payload).pipe(
      map((res) => {
        return res
      })
    )
  }
  
  getAMLData(payload){
    return this.http.post<any>(`${this.KYS_API_URL}/v2/crr-data/search/`,payload).pipe(
      map((res) => {
        return res
      })
    )
  }

  createProject(payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kyb/project-starship/register`,payload).pipe(
      map((res) => {
        return res['data']
      })
    )
  }

  createNonRelationalCase(payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kychub/kyb/case/v1/create`,payload).pipe(
      map((res) => {
        return res['data']
      })
    )
  }

  getRelationGraphData(id){
    return this.http.get(`${this.KYS_API_URL}/v2/aml/rca/${id}`).pipe(map((res: any) => res.data)); 
  }

  getRelationGraphDataImmediate(id){
    return this.http.get(`${this.KYS_API_URL}/v2/aml/immediate/rca/${id}`).pipe(map((res: any) => res.data)); 
  }

  getRiskTemplate(){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/risk/all?kysAccountType=CORPORATE`).pipe(map((res: any) => res.data)); 
  }

  getUboDetailsByKysNo(kysNo){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/officer-details/${kysNo}`).pipe(map((res: any) => res.data)); 
  }

  caseActivityAdd(payload, id){
    return this.http.post<any>(`${this.KYS_API_URL}/kyb/case-activity/add/${id}`,payload).pipe(
      map((res) => {
        return res['data']
      })
    )
  }

  getCaseActivityAll(payload, id){
    return this.http.post<any>(`${this.KYS_API_URL}/kyb/case-activity/get-all/${id}`,payload).pipe(
      map((res) => {
        return res['data']
      })
    )
  }

  fetchEventsTableData(kysNo,payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/event/filter/${kysNo}`,payload).pipe(map((res: any) => res.data));
  }

  fetchEventsDetailsData(eventId){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/event/fetch/${eventId}`).pipe(map((res: any) => res.data));
  }

  fetchDocs(countryCode, docType){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/available/docs/${countryCode}?type=${docType}`).pipe(map((res: any) => res.data));
  }

  createPolicy(payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/risk/create`,payload).pipe(map((res: any) => res));
  }
  
  getAccountDataByKysNo(kysNo){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/aof/${kysNo}`).pipe(map((res: any) => res.data));
  }

  getAMLDataSourcesData(){
    return this.http.get(`${this.KYS_API_URL}/fincai/analytics/overall-update`).pipe(map((res: any) => res.data));
  }

  documentAcceptReject(payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/document/check/accept-reject/document`,payload).pipe(map((res: any) => res));
  }

  documentOverride(payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/document/check/override-document`,payload).pipe(map((res: any) => res));
  }

}
