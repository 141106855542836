import { Component, HostListener, Input, OnInit, Output } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "../../services/data.service";
import { MessagingService } from "../../services/messaging.service";
import { NotificationService } from "../../services/notification.service";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "./../../shared/kyc-modal/kyc-modal.component";
import { EventEmitter } from "@angular/core";
import { AppService } from "../../services/app.service";
import { FcmMessagingService } from "../../services/fcmMessaging.service";


@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  sideBarHidden: boolean;
  currentRoute: string;

  //Modal
  bsModalRef: BsModalRef;

  notifications: any[] = [];
  totalElements: number = 0;
  messageBehaviour: any;
  showTutorial:boolean=false;
  amlAlerts:any[]=[];
  // notificationType: "webhook" | "api-key-generated" ;

  constructor(
    private router: Router,
    private _data: DataService,
    private _noti: NotificationService,
    private messagingService: MessagingService,
    private fcmService:FcmMessagingService,
    private modalService: BsModalService,
    public route: ActivatedRoute,
    public app: AppService
  ) {
    this.messageBehaviour = this.messagingService.currentMessage;
    router.events.subscribe(val=>{
      if(router.url==='/home'){
        if (screen.width < 1200){
          this.showTutorial=false;
        }
        else{
          this.showTutorial = true;
        }
      }
      else{
        this.showTutorial=false;
      }
    })
    this.fcmService.currentMessage.subscribe(payload=>{
      if(payload?.data?.title){
        this.totalElements++;
      }
      
    })
  }

  @HostListener("window:resize", ["$event"])
  public onResize(event) {
    if (event.target.innerWidth < 1200) {
      this.showTutorial = false;
    }
    else{
      if(this.router.url==='/home'){
        this.showTutorial=true;
      }
    }
  }

  ngOnInit() {
    let data = {
      page: 0,
      size: 10,
      sort: "ASC"
    };
    let type = "unread"; //read | unread | all
    //add this when notification is ready
    // this._noti.getAllNotification(data , type)
    // .subscribe(
    //     (res)=>{
    //         this.notifications = res.data.content ;
    //         this.totalElements = res.data.totalElements ;
    //         // this.totalElements = res.data.totalElements ;
    //         console.log(this.notifications);
    //     }
    // );
    this.getNotificationData();
    this._data.sideBarHiddenInfo.subscribe(data => this.sideBarHidden = data);
  }

  // hideSideBar() {
  //   var element = document.getElementById("wrapper");
  //   if (!this.sideBarHidden) {
  //     //hide it
  //     element.classList.add("forced", "enlarged");
  //     this.sideBarHidden = true;
  //   } else {
  //     // unhide it
  //     element.classList.remove("forced", "enlarged");
  //     this.sideBarHidden = false;
  //   }
  // }
  @Input() public currentTheme: string;
  @Output() changedTheme: EventEmitter<any> = new EventEmitter<any>();
  themeChange: boolean = false;
  themeToggle(){
    if(this.currentTheme == 'theme-light'){
      localStorage.setItem('currentTheme', 'theme-dark');
      this.changedTheme.emit('theme-dark');
      this.app.setCurrentTheme('theme-dark');
    }else if(this.currentTheme == 'theme-dark'){
      this.changedTheme.emit('theme-light');
      localStorage.setItem('currentTheme', 'theme-light');
      this.app.setCurrentTheme('theme-light');
    }
  }
  logOut() {
    const initialState = {
      title: `Log Out`,
      type: "user-logout"
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: `modal ${
        this.currentTheme === "theme-dark" ? "modal-dark" : "modal-light"
      }`,
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      console.log("results", result);
      if (Object.keys(result).length) {
        console.log(result);
        if (result.shouldLogout) {
          this.doLogout();
        }
      }
    });
  }

  doLogout() {
    this._data.removeAllCookie();
    this._data.changeToken(null);
    this._data.changeClientStandardServices(null);
    this._data.changeClientStandardChecks(null);
    this.messageBehaviour.unsubscribe();
    this.router.navigate(["/login"]);
  }

  getNotificationData(){
    this._noti.newNotificationData().subscribe((res:any)=>{
      this.totalElements = res.data.length ;
      this.notifications = res.data;
      this._noti.notifications.next(res.data);
      this.getAmlAlerts();
    })
  }

  getAmlAlerts(){
    this._noti.AMLalertsData().subscribe((res:any)=>{
      this.totalElements=this.totalElements+res.data.length;
      this.amlAlerts=res.data;
      this._noti.amlAlerts.next(res.data);
      
    })
  }
  getNotificationIcon(slug, type) {
    let notiDetail = [
      {
        slug: "api-key-generated",
        color: "bg-danger",
        icon: "fa-key"
      },
      {
        slug: "webhook",
        color: "bg-warning",
        icon: "fa-cog"
      }
    ];

    let selectedNoti = notiDetail.filter(obj => obj.slug == slug);
    if (selectedNoti && selectedNoti.length) {
      return selectedNoti[0][type];
    } else {
      return type == "color" ? "bg-primary" : "fa-info-circle";
    }
  }

  markRead(notificationID) {
    this._noti.markAsRead(notificationID).subscribe(res => {
      this.getNotificationData()
    });
  }
  markAMLRead(notificationID){
    this._noti.markAsReadAML(notificationID).subscribe(res => {
      this.getNotificationData()
    });
  }

  toggleTutorial(){
    this.app.toggleTutorial(true);
  }
}
