import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LegalOnboardingService } from '../../pages/legal-onboarding/services/legal-onboarding.service';

@Component({
  selector: 'app-id-verification',
  templateUrl: './id-verification.component.html',
  styleUrls: ['./id-verification.component.scss']
})
export class IdVerificationComponent implements OnInit {

 @Input() item;
 @Input() headingShow? : boolean = false;
 @Input() accountType? : string;
 @Input() index?;
  docData: any;
  faceImageUrl: any;
  extractedInfo: any = {};
  shouldFlip: boolean = false;
  isImagePdf: boolean = false;
  frontImage: SafeUrl;
  backImage: SafeUrl;
  verifiedResultData: any;
  individualInfo: Object;
  entityName: string;
  firstName: string = '';
  lastName: string = '';
  middleName: string = '';

  constructor(private domSanitizer: DomSanitizer, private legalService: LegalOnboardingService) { }

  ngOnInit() {
      this.faceImageUrl = this.item.extractedFace;
      this.docData = this.item

      // Verification Reults
      this.legalService.getVerifiedResult(this.item.checkId).subscribe((res) => {this.verifiedResultData = res
      })

      // Individual Information By Kys No
      if(this.item.tpe !== null && this.item.tpe !== undefined){
        this.legalService.getIndividualInformationByKysNo(this.item.tpe.kysNo).subscribe((res) => {
          if(res){
            this.individualInfo = res;
            this.firstName = this.individualInfo?.["firstName"] || '';
            this.middleName = this.individualInfo?.["middleName"] || '';
            this.lastName = this.individualInfo?.["lastName"] || '';
            this.entityName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
            this.entityName = this.entityName.trim().replace(/ +/g, ' ');
          }
        });
      }

      // Front Image URL
      if(this.item.docPathF !== null && this.item.docPathF !== undefined){
        this.legalService.getDocumentUrl(this.item.docPathF).subscribe((url : string) => this.frontImage = this.domSanitizer.bypassSecurityTrustResourceUrl(url));
        if(this.item.docPathF.substring(this.item.docPathF.length - 3) === "pdf"){
          this.isImagePdf = true;
        }
      }

      // Rear Image URL
      if(this.item.docPathR !== null && this.item.docPathR !== undefined){
        this.legalService.getDocumentUrl(this.item.docPathR).subscribe((url : string) => this.backImage = this.domSanitizer.bypassSecurityTrustResourceUrl(url));
      }

      // Extraction Results
      const extration = JSON.parse(this.item?.extractionResult);
      if(extration){
        if(extration?.extractionType && extration?.extractionType != '' && extration?.extractionType != 'null'){
          this.extractedInfo['type'] = extration.extractionType;
        }
        if(extration?.name && extration?.name != '' && extration?.name != 'null'){
          this.extractedInfo['name'] = extration.name;
        }
        if(extration?.sn && extration?.sn != '' && extration?.sn != 'null'){
          this.extractedInfo['sn'] = extration.sn;
        }
        if(extration?.dob && extration?.dob != '' && extration?.dob != 'null'){
          this.extractedInfo['dob'] = extration.dob;
        }
        if(extration?.issuedate && extration?.issuedate != '' && extration?.issuedate != 'null'){
          this.extractedInfo['issuedDate'] = extration.issuedate;
        }
        if(extration?.expirydate && extration?.expirydate != '' && extration?.expirydate != 'null'){
          this.extractedInfo['expirydate'] = extration.expirydate;
        }
        if(extration?.fname && extration?.fname != '' && extration?.fname != 'null'){
          this.extractedInfo['fname'] = extration.fname;
        }
        if(extration?.lname && extration?.lname != '' && extration?.lname != 'null'){
          this.extractedInfo['lname'] = extration.lname;
        }
      }
  }

  swapImage(img: HTMLImageElement): void {
    img.src = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded';
  }
    
  flip() {
    this.shouldFlip = !this.shouldFlip;
  }


    // data should be your response data in base64 format
  openPdf(data){
    this.legalService.getDocumentUrl(data).subscribe(response=>{
      var byteCharacters = atob(response.split(',')[1]);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL,'_blank');
    })
    
  }
}

