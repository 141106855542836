export * from './risk-policy/risk-policy.component';
export * from './new-aml-summary/new-aml-summary.component';
export * from './id-verification/id-verification.component';
export * from './aml-summary-details/aml-summary-details.component';
export * from './all-doc-upload/all-doc-upload.component';
export * from './selfie-verification/selfie-verification.component';
export * from './liveliness-verification/liveliness-verification.component';
export * from './questionaire/questionaire.component';
export * from './widgets/widgets.component';
export * from './trigger-work-flow/trigger-work-flow.component';
export * from './trigger-work-flow/trigger-work-flow-screen-two/trigger-work-flow-screen-two.component';
export * from './trigger-work-flow/trigger-work-flow-screen-three/trigger-work-flow-screen-three.component';
export * from './entity-details-page/entity-details-page.component';