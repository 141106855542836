import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LegalOnboardingService } from '../../pages/legal-onboarding-2.0/services/legal-onboarding.service';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NewKycModalComponent } from '../../../app/shared/new-kyc-modal/new-kyc-modal.component';

@Component({
  selector: 'app-entity-details-page',
  templateUrl: './entity-details-page.component.html',
  styleUrls: ['./entity-details-page.component.scss']
})
export class EntityDetailsPageComponent implements OnChanges {
  @Input() entityObject: any;
  @Output() close = new EventEmitter<any>();
  accountId: any;
  docInfo: any;
  score$: Observable<number>;
  riskData: any;
  idvScoreStatus: any;
  riskScreening: any[] = [];
  companyAml: any;
  companyInfo: any;
  verifiedUBOs: number = 0;
  uboList: any[] = [];
  verifiedDocs: number = 0;
  checkListEntries: any;
  anyOfficerFound: boolean;
  numberData: number;
  checkListArray: any = [];
  adverseMedia = {
    status: 'Low',
    score: 0,
  };
  unverfiedDocList: any[] = [];
  totalDocsList: any[] = [];

  public bsModalRef: BsModalRef;
  crrGuid: any[]=[];

  constructor(
    private legalServices: LegalOnboardingService,
    private dataService: DataService,
    private route: Router,
    private modelService: BsModalService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.entityObject?.currentValue){
      this.entityObject = changes?.entityObject?.currentValue
      if(this.entityObject){
        this.entityObject = JSON.parse(this.entityObject);
        const completedChecks = this.entityObject?.completedChecksList || [];
        const totalChecks = this.entityObject?.totalChecksList || [];
        let leftChecks = [];
        leftChecks = totalChecks.filter((f) => !completedChecks.includes(f));
        this.entityObject['leftChecks'] = leftChecks
      }
    }
    if(this.entityObject?.kysAmlSummary) this.companyAml = this.entityObject?.kysAmlSummary;
    if(this.entityObject?.addedEntity && this.entityObject?.addedEntity?.account?.accountId){
      this.accountId = this.entityObject?.addedEntity?.account?.accountId;
      this.getUboList();
      this.getDocumentsInfo();
      this.getRiskScore(this.accountId);
      this.getAllCheck(this.accountId);
      this.getCompanybasicDetails();
      this.getCompanyChecks();
    }
  }

  getCompanyChecks(){
    this.legalServices.getCheckList(this.accountId).subscribe((res) => {
      if (res !== null && res !== undefined) {
        this.checkListEntries = res.entries;
        this.anyOfficerFound = true;
      }
      for(let item of this.checkListEntries){
        this.numberData += 1;
        this.getIndexValue(this.numberData-1);
      }
    }); 
  }

  getIndexValue(i){
    if(this.checkListArray.indexOf(i) > -1){
      this.checkListArray.splice(this.checkListArray.indexOf(i), 1)
    }
    else{
      this.checkListArray.push(i)
    }    
  }

  getCompanybasicDetails(){
    this.legalServices.getCorporateAccountInfo(this.accountId).subscribe((res) => {
      this.companyInfo = res;
    });
  }

  getDocumentsInfo(){
    this.verifiedDocs = 0;
    this.unverfiedDocList = [];
    this.totalDocsList = []
    this.legalServices.getDocList(this.accountId).subscribe((res) => {
      let response  = res || [];
      response.forEach(item => {
        if(item.docType != 'SELFIE' && item.docType != 'LIVELINESS'){
          if(item?.status == 'VERIFIED'){
            this.verifiedDocs += 1;
            this.totalDocsList.push(item?.docName);
          }
          else if(item?.checkStatus != 'INITIATED') {
            this.unverfiedDocList.push(item?.docName);
            this.totalDocsList.push(item?.docName);
          }
        }
      })
    });
    this.score$ = this.legalServices.getOverAllScore(this.accountId);
    this.legalServices.getDocumentsInfo(this.accountId).subscribe((res) => {
      if(res){
        this.docInfo = res;
        if(this.docInfo?.warningList?.length){
          this.docInfo.warningList = this.docInfo?.warningList.map((e) => e?.value);
        }
      }
    });
  } 

  getUboList(){
    this.legalServices.getManualUbo(this.accountId).subscribe((res) => {
      this.uboList = res.map(e=> e.firstName) || [];
      this.riskScreening = res || [];
      console.log(this.riskScreening,999)
      const promises = this.riskScreening.map(async (e) =>{
        const amlData = await this.getRiskScreening(e?.id);
        const adverseData = await this.getAdverseMediaStatus(e?.tpe?.kysNo);
        const idvVerificationScore = await this.getIdvData(this.accountId, e?.tpe?.kysNo, e?.controlOfficerType);
        let amlRiskStatus = amlData?.riskStatus;
        let isSantionFound: boolean = false;
        let isCrimeFound: boolean = false;
        let isWatchListFound: boolean = false;
        let amlRiskScore: number = 0;
        let adverseMediaScore: number =0;
        if(amlData){
          if(amlData?.aml){
            isSantionFound = amlData?.aml?.SANCTION;
            isCrimeFound = amlData?.aml?.CRIME;
            isWatchListFound = amlData?.aml?.WATCHLIST;
          }
          if(amlData?.uboRiskScore?.data && amlData?.uboRiskScore?.data?.content?.length && amlData?.uboRiskScore?.data?.content[0]?.riskProfile){
            amlRiskScore = Math.round(amlData?.uboRiskScore?.data?.content[0]?.riskProfile?.score);
          }
          else if(amlData?.score){
            amlRiskScore = Math.round(amlData?.score);
          }
        }
        if(adverseData?.riskScore){
          adverseMediaScore = Math.round(adverseData?.riskScore);
        }
        return {
          ...e, isSantionFound, isCrimeFound, isWatchListFound, amlRiskScore, adverseMediaScore, amlRiskStatus, idvVerificationScore
        }
      });
      Promise.allSettled(promises).then((res) => {
        this.riskScreening = res.map((f) => f['value']);
      });
      if(res?.length){
        for(let item of res){
          if(item?.tpe?.kysNo){
            this.legalServices.getAMLStatus(item?.tpe?.kysNo).subscribe((status) => {
              if(status == 'LOW'){
                this.verifiedUBOs += 1;
              }
            })
          }
        }
      }
    });
  }

  async getRiskScreening(id){
    this.dataService.changeLoaderVisibility(true);
    const amlData = await this.legalServices.getRiskScreening(id).toPromise();
    this.dataService.changeLoaderVisibility(false);
    return amlData;
  }

  async getAdverseMediaStatus(id){
    this.dataService.changeLoaderVisibility(true);
    const adverseData = await this.legalServices.getAdverseMediaStatus(id).toPromise();
    this.dataService.changeLoaderVisibility(false);
    return adverseData;
  }

  getRiskScore(accountId){
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.getLegalRiskScore(accountId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      if (res) {
        this.riskData = res;
      }
    });
    this.legalServices.getOverallStatus(accountId).subscribe((res) => {
      this.idvScoreStatus = res === "REJECTED" ? "HIGH" : "LOW";
    });
  }

  async getIdvData(accountId, kysNo, type){
    this.dataService.changeLoaderVisibility(true);
    const res = await this.legalServices.getAllCheck(accountId).toPromise();
    this.dataService.changeLoaderVisibility(false);
    if (res?.length) {
      for(let item of res){
        if(item[type]){
          for(let ubo of item[type]){
            if(kysNo == ubo?.tpe?.kysNo  && ubo?.idvChecks?.[0]?.IDENTITY?.[0]){
              return ubo?.idvChecks?.[0]?.IDENTITY?.[0]
            }
            else{
              return null
            }
          }
        }
        else{
          return null
        }
      }
    }
    else{
      return null
    }
  }

  getAllCheck(accountId){
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.getAllCheck(accountId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      if (res?.length) {
        for(let item of res){
          if(item?.CORPORATE){
            if(item?.CORPORATE?.length && item?.CORPORATE[0]?.amlChecks?.length && item?.CORPORATE[0]?.amlChecks[0]?.adversemediaCheck?.length){
              this.adverseMedia['status'] = item?.CORPORATE[0]?.amlChecks[0]?.adversemediaCheck[0]?.riskFactor
              this.adverseMedia['score'] = item?.CORPORATE[0]?.amlChecks[0]?.adversemediaCheck[0]?.riskScore
            }
            if(item?.CORPORATE?.length && item?.CORPORATE[0]?.amlChecks?.length){
              let amlCheck = item?.CORPORATE[0]?.amlChecks[0]?.amlChecks
              for(let eh of amlCheck){
                if(eh?.amlStatus === "FAIL"){
                  var corpObj = {type: 'CORPORATE', child: []};
                  let corpChild = {name: this.entityObject?.entityName, guid: eh?.dataSourceGUID, value: []};
                  const value = [
                    {
                      key: 'Corporate Aml AML Risk',
                      score: 20,
                      weight: 0.4,
                      riskUnitRes: [
                        {
                          key: 'Individual Sanction',
                          score: 1,
                          weight: 10
                        },
                        {
                          key: 'Watch List',
                          score: 5,
                          weight: 10
                        },
                        {
                          key: 'Offshore Database Leak',
                          score: 1,
                          weight: 10
                        }
                      ]
                    },
                    {
                      key: 'Corporate Aml Adverse Media',
                      score: 18,
                      weight: 0.1,
                      riskUnitRes: [
                        {
                          key: 'Adverse Media Risk',
                          score: 1,
                          weight: 9
                        }
                      ]
                    },
                    {
                      key: 'Corporate Aml Entity Risk',
                      score: 16,
                      weight: 0.05,
                      riskUnitRes: [
                        {
                          key: 'Entity Sector Risk',
                          score: 1,
                          weight: 8
                        },
                        {
                          key: 'Business Wide Risk',
                          score: 1,
                          weight: 8
                        },
                        {
                          key: 'Regulated Entity',
                          score: 1,
                          weight: 7
                        },
                      ]
                    },
                    {
                      key: 'Corporate Aml GeoLocation',
                      score: 20,
                      weight: 0.4,
                      riskUnitRes: [
                        {
                          key: 'Basel Index score',
                          score: 1,
                          weight: 8
                        },
                        {
                          key: 'Corporate Enhanced Due Diligence Location',
                          score: 1,
                          weight: 10
                        },
                        {
                          key: 'Corporate Tax Haven Location',
                          score: 1,
                          weight: 9
                        },
                        {
                          key: 'Operating Jurisdictions',
                          score: 1,
                          weight: 9
                        },
                        {
                          key: 'Registered Jurisdictions',
                          score: 1,
                          weight: 9
                        },
                      ]
                    },
                  ]
                  corpChild['value'] = value;
                  corpObj['child'].push(corpChild);
                  this.crrGuid.push(corpObj);
                  break;
                }
              }
            }
          }
        }
      }
    });
  }

  routeToLegalReport(data, type){
    this.close.emit();
    if(type == 'ubo'){
      this.route.navigate(['/new-legal-onboarding/cm-ubo-legal-report',data?.tpe?.kysNo],
      {
        queryParams:{
          'from1':'CM',
          'caseId': this.entityObject?.caseId
        }
      })
    }
    else if(type == 'legal'){
      this.route.navigate(['/new-legal-onboarding/cm-legal-report',data?.account?.accountId],
      {
        queryParams:{
          'from':'CM',
          'caseId': this.entityObject?.caseId
        }
      })
    }
  }

  getCRRModel(){
    const initialState = {
      type: "crr-break-points",
      crrGuidDetails: this.crrGuid
    }
    this.bsModalRef = this.modelService.show(NewKycModalComponent, { initialState, class: 'modal-lg new-modal-as-dialogue' });
  }

}
