export class ClientData{
      clientType        : any;
      name              : any;
      email             : any;
      userName          : any;
      addressLine1      : any;
      addressLine2      : any;
      city              : any;
      state             : any;
      postalCode        : any;
      country           : any ={countryName:null};
      countryCode       : any;
      website           : any;
      companyID         : any;
      industryType      : any;
      companyType       : any;
      companySize       : any;
      fbProfileUrl      : any;
      twitterProfileUrl : any;
      linkedInProfileUrl: any;
      contactNumber     : any;
      createdDate       : any;
      lastModifiedDate  : any;
}

export class ClientConfig{
      passStp       : any;
      failStp       : any;
      logoUrl       : any ;
      dashboardTheme: any ;
      feAsTheme     : any ;
}

import { Component, OnInit , OnDestroy, Input, HostListener} from "@angular/core";
import { LayoutService } from "../../services/layout.service";
import { Router , NavigationEnd } from "@angular/router";
import { DataService } from '../../services/data.service';
import { filter } from 'rxjs/operators';
import { AppService } from "../../services/app.service";

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {KycModalComponent} from './../../shared/kyc-modal/kyc-modal.component';
import { Subscription } from "rxjs";
import { FcmMessagingService } from "../../services/fcmMessaging.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  @Input() public currentTheme: string;
  hasSub          : boolean[] = [];
  layoutMenus     : any = [];
  currentUrl      : any ;
  
  bsModalRef      : BsModalRef;
  sideBarHidden: boolean=false;
  //client profile
  clientData      : any = new ClientData();
  
  standardServices: any = null ;

  subscriptions : Subscription[] = []; 

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth < 992){
      this.sideBarHidden = true
    }else{
      this.sideBarHidden = false
    }
  }

  constructor(
    private _layoutService: LayoutService, 
    private router        : Router,
    private data          : DataService,
    private modalService  : BsModalService,
    private appService    : AppService,
    private firebaseMsg:FcmMessagingService

  ){
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(e => {
      this.currentUrl = e["url"];
      this.hasSub = [];
      // console.log(this.currentUrl);
      this.getSub(this.currentUrl);
      // console.log(this.hasSub);
      if(window.innerWidth < 992){
        this.sideBarHidden = true
      }else{
        this.sideBarHidden = false
      }
    });
  }

  getSub(currentUrl){
    let layoutMenu = this.layoutMenus;
    for (var i = 0; i < layoutMenu.length; ++i) {
      if(layoutMenu[i].router === currentUrl){
        this.hasSub[i] = true;
      }else{
        for (var j = 0; j < layoutMenu[i].children.length; ++j) {
          if(layoutMenu[i].children[j].router === currentUrl){
            this.hasSub[i] = true;
          }
        }
      }
    }
  }

  isRouteAccessible(item){
    // return true;
    // if(item && !item["access_slug"]){
    //   return true;
    // }
    // else if(item && item["access_slug"] && this.standardServices){
    if(this.standardServices){
      let isPresent = this.standardServices.filter(obj=>obj.slugGroup == item["access_slug"]);
      var allowedServices = this.data.isSidebarServicesPresent(this.standardServices);
      
      // console.log(allowedServices.includes(this.getSlug(item["title"])));
      if(isPresent && isPresent.length && !allowedServices.includes(this.getSlug(item["title"]))){
        return true;
      }else{
        return false;
      }
    }
    else return false;
  }
  // to get slug
  getSlug(data){
    return data.toLowerCase().replace(/ /g, '-');
  }

  ngOnInit() {
    // this.getLayout("admin");
    var a = this.data.loadClientData.subscribe(
      (val)=>{ 
        if(val !=null){
          this.clientData = val; 
          setTimeout(() => {console.log("Client Data: ", this.clientData);
            window.localStorage.setItem("chatBotClientCompanyName", this.clientData.name);
            window.localStorage.setItem("chatBotClientEmail", this.clientData.email);
            console.log("KycHub: ", window.localStorage.getItem("chatBotClientCompanyName"));
            console.log("KycHub: ", window.localStorage.getItem("chatBotClientEmail"));}
            )
        }
      }
    );
    this.data.subscriptions.push(a);

    var standardServices;
    var b = this.data.loadClientStandardServices.subscribe(
      (val)=>{ this.standardServices = val; 
        if(this.standardServices!=null){
          console.log("standardServices1: ",this.standardServices);
          this.firebaseMsg.requestPermission();
          this.appService.devOpsRestriction().subscribe((res) =>{
            if(res.slug==='ROLE_SUPERADMIN'){
              this.getLayout('ROLE_SUPERADMIN',this.standardServices);
            }
            else if(res.slug === 'ROLE_DEVOPS')
            {
              this.getLayout('ROLE_DEVOPS',this.standardServices);
              
            }
            else{
              console.log("else part", res.slug);
              this.getLayout('admin',this.standardServices);
            }
          });
            // this.getLayout("admin",this.standardServices);
        }
      }
    );
    setTimeout(()=>{
      
    });
    this.data.subscriptions.push(b);
    
   

  }


  getLayout(role: any,standardServices) {
    // console.log("standrd serve in getlayout: ",standardServices);
    let serviceItem=standardServices.find(item=>item['slug']=='india-kyc')
    // this.layoutMenus = this._layoutService.getLayout(role);
    this._layoutService.getUserMenu().subscribe(res=>{
        let menu  = this._layoutService.getLayout(role,res);
      let isIndia=serviceItem!=undefined?true:false;
      // console.log("menu: ",menu);
      window.sessionStorage.setItem('isIndia',isIndia?'india':'international')
      if(isIndia){
        this.layoutMenus = menu.filter((layout: any) => {
          // return layout..toLowerCase() === role.toLowerCase();
          return layout['customer'].includes('india')
        });
      }
      else{
          this.layoutMenus = menu.filter((layout: any) => {
            // return layout..toLowerCase() === role.toLowerCase();
            return layout['customer'].includes('international')
          });
      }
    });
    

    console.log(this.layoutMenus);
  }

  activeRoute(routename: string): boolean {
    // console.log(this.router.url);
    return this.router.url == routename;
  }

  //click hndler for sub menu
  subMenuClick(item, e) {
    console.log(item.title);
    // var individualHeight = 38;
    if(item.children.length) {
      document.getElementById(item.title).classList.toggle("rotateDown");
      document.getElementById("dropdown" + e).classList.toggle("show");
      // document.getElementById("dropdown" + e).style.height = (document.getElementById("dropdown" + e).style.height.length) ? "0px" : (individualHeight * parseInt(item.children.length)) + "px";
    }
  }

  openLink(item , i){
    if(item.children.length){
      this.subMenuClick(item ,  i) ;
    }
    else{
      this.router.navigate([item.router]);
    }
  }

  activeClass(item){
    // console.log("this.router.url"+this.router.url);
    // console.log("this.currentUrl"+this.currentUrl);
    var cond1 = this.currentUrl == item.router ;
    var hasRouter ;
    if(item.children.length){
      var arr = item.children;
      var hasRouter = arr.filter((a) => {
        return a.router == this.currentUrl ;
      });
    }
    if(hasRouter){
      if(hasRouter.length){
        return 'active'
      }
    }
    else if(cond1){
      return 'active';
    }
    else{
      return null;
    }
  }

  hasSubChange(index : any, menu : object){
    // if(document.querySelector('app-sidebar > div.side-menu')['offsetWidth'] !== 70){
      // console.log(menu);
    if(menu["router"] == "/developer"){
      window.open("https://documenter.getpostman.com/view/15897062/UyxqCiHz" , "_blank");
      return;
    }

    if(menu['router']){
        this.router.navigate([menu['router']]);
        if(!this.hasSub[index]){
            this.hasSub = [];
        }
        this.hasSub[index] = !this.hasSub[index];
    }else{
        if(!this.hasSub[index]){
            this.hasSub = [];
        }
        this.hasSub[index] = !this.hasSub[index];
    }
    // }
}

  // logOut(){
  //   this.data.removeAllCookie();
  //   this.router.navigate(['/login'])
  // }
    logOut(){
        const initialState = {
            title: `Log Out`,
            type: "user-logout"
        };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
            initialState,
            class: `modal ${
              this.currentTheme === "theme-dark" ? "modal-dark" : "modal-light"
            }`,
        });
        this.bsModalRef.content.closeBtnName = "Close";
        console.log(this.bsModalRef.content.onClose);
        this.bsModalRef.content.onClose.subscribe(result => {
            console.log("results", result);
            if (Object.keys(result).length) {
                console.log(result);
                if(result.shouldLogout){
                    this.doLogout();
                }
            }
        });
    }

    doLogout(){
        this.data.removeAllCookie();
        this.data.unsubscribeAll();
        this.router.navigate(['/login']);
    }


    hideSideBar() {
      var element = document.getElementById("wrapper");
      if (!this.sideBarHidden) {
        //hide it
        element.classList.add("forced", "enlarged");
        this.sideBarHidden = true;
      } else {
        // unhide it
        element.classList.remove("forced", "enlarged");
        this.sideBarHidden = false;
      }
      this.data.changeSideBarHidden(this.sideBarHidden);
    }
}


