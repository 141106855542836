import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent implements OnInit {

  @Input() pageName;
  @Input() cardsForSkeleton;

  timeDurationOptions = [
    { name: "Week", value: "1w" },
    { name: "Month", value: "1M" },
    { name: "6 Months", value: "6M" },
    { name: "Year", value: "1y" },
  ];
  selectedTimeDurationValue = '1M';
  isLoading:boolean = false;
  cardSize: any = [1, 2, 3,];

  slideConfigWidgetWrap = {
    slidesToShow:3,
    slidesToScroll: 1,
    infinite:false,
    responsive: [
      {
        breakpoint: 1368,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
    // prevArrow: '<span class="icon" style="align-items:flex-start;"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-up fa-2x" aria-hidden="true"></i></span>',
    // nextArrow: '<span class="icon" style="align-items:center"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-down fa-2x" aria-hidden="true"></i></span>',

  };
  
  constructor() { }
  ngOnInit() {
    
  }

  onChangeTimeDuration(value:string){}
}
  