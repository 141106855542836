import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trigger-work-flow-screen-three',
  templateUrl: './trigger-work-flow-screen-three.component.html',
  styleUrls: ['./trigger-work-flow-screen-three.component.scss','../trigger-work-flow.component.scss']
})
export class TriggerWorkFlowScreenThreeComponent implements OnInit {
  tabs: any[] = [
    {
      heading: 'All',
      slug: 'all'
    },
    {
      heading: 'Periodic trigger',
      slug: 'periodic-trigger'
    },
    {
      heading: 'Event based trigger',
      slug: 'event-based-trigger'
    }
  ];
  activeTab: string='all';
  constructor() { }

  ngOnInit(): void {
  }

  selectedTabs(slug: string){
    this.activeTab = slug;
  }

}
