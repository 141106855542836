import { Component, OnInit ,Input,Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-trigger-work-flow-screen-two',
  templateUrl: './trigger-work-flow-screen-two.component.html',
  styleUrls: ['./trigger-work-flow-screen-two.component.scss','../trigger-work-flow.component.scss']
})
export class TriggerWorkFlowScreenTwoComponent implements OnInit {

  checkBoxSelected: boolean;
  isAllRowSelected: any;
  @Input()showSchedule;
  @Output() setShowSchedule = new EventEmitter()

  dummyData=[
    {entityName:'Greensill',entityType:'Corporate',email:'greensill@gmail.com'},
    {entityName:'Greensill',entityType:'Corporate',email:'greensill@gmail.com'},
    {entityName:'Greensill',entityType:'Corporate',email:'greensill@gmail.com'}
  ]

  constructor() { }

  ngOnInit(): void {
  }

  allSelected(selectType, item){
    this.checkBoxSelected = true;
    if(selectType == 'all-select'){
      
      this.dummyData.forEach(element => {
        this.isAllRowSelected ? element['value'] = false : element['value'] = true;
      });
    }
  }

  schedule(){
    this.setShowSchedule.emit(!this.showSchedule);
  }

}
