import { Component, OnInit } from '@angular/core';
import { AppService } from './../../services/app.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-toaster-as-dialogue',
  templateUrl: './toaster-as-dialogue.component.html',
  styleUrls: ['./toaster-as-dialogue.component.scss']
})
export class ToasterAsDialogueComponent implements OnInit {
  currentTheme: string = localStorage.getItem("currentTheme");
  /* 
    Image URL 
    'successfull' :  '/assets/images/toaster/event-success.svg',
    'error' : '/assets/images/toaster/event-error.svg',
    'alert' : '/assets/images/toaster/event-alert.svg',
    'check passed' : '/assets/images/toaster/Check_Pass.svg',


    To call this dialogue
    
    const initialState = {
        imageUrl: '',
        heading: '',  
        subText: '',
        type: '',
        button1: '',
        button2: '',
        button: '',
      }
    this.modalService.show(ToasterAsDialogueComponent, { initialState, 
      class: `modal-dialog-centered toaster-modal ${
      this.currentTheme === "theme-dark" ? "modal-dark" : "modal-light"
    }` });
  */
  heading: string = '';
  imageUrl: string = '';
  subText: string = '';
  button1: string = '';
  button2: string = '';
  button: string = '';
  type: 
    | "alert"
    | "info"
    | "error"
    | "success"
    | "warning"

  constructor(public bsModalRef: BsModalRef, private _app: AppService) { }

  ngOnInit(): void {
    this._app.currentTheme.subscribe((theme) => {
      this.currentTheme = theme;
      let themeCall = document.getElementsByClassName('modal-content')[0];
      themeCall.classList.add(this.currentTheme ? this.currentTheme : 'theme-dark');
    });
  }

  closeModal(){ // To close the modal
    this.bsModalRef.hide()
  }

}
