import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';

@Component({
  selector: 'app-trigger-work-flow',
  templateUrl: './trigger-work-flow.component.html',
  styleUrls: ['./trigger-work-flow.component.scss']
})
export class TriggerWorkFlowComponent implements OnInit {
  triggerLastScreen:boolean = false;
  triggerOneSubmitted: boolean = false;
  triggerTwoSubmitted: boolean = false;
  showSchedule: boolean=false;
  selectedTypeTrigger:string='periodic-trigger';
  @Input() entityObject: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  triggerAsNew: FormGroup = this.formBuilder.group({
    selectedTrigger:['', Validators.required],
    workFlowToTrigger:['', Validators.required],
    alertMode: ['', Validators.required],
    custom: [''],
    numValue: [''],
    week: [''],
    repeatsOn:[''],
    endsOn:[''],
  });
  triggerAsNewTwo: FormGroup = this.formBuilder.group({
    workFlowToTrigger:['', Validators.required],
    alertMode: ['', Validators.required],
    eventOn: ['', Validators.required],
  });

  repeatsOnValues=['S','M','T','W','TH','F','SA']
  repeatsOn:any=[];

  officerTypeArray: any[] = [
    { name: 'UBO' },
    { name: 'DIRECTOR' }
  ];

  periodicEvent = {
    workflowToTriggerList : [
      { name: 'Enhanced Corporate Due Diligence Workflow' , value:'Enhanced Corporate Due Diligence Workflow'},
      { name: 'Corporate Due Diligence Review Workflow' , value:'Corporate Due Diligence Review Workflow'},
      { name:'Trade Based Money Laundering Workflow' , value:'Trade Based Money Laundering Workflow'},
      { name:'Investment Due Diligence Workflow' , value:'Investment Due Diligence Workflow'},
    ],
    alertMode:[
      { name:'Dashboard Notification' , value:'Dashboard Notification'},
      { name:'Email Notification' , value:'Email Notification'},
      { name:'Webhook Notification' , value:'Webhook Notification'},
    ],
    setRecurrence:[
      { name:'Weekly' , value:'Weekly'},      
      { name:'Monthly' , value:'Monthly'},
      { name:'Quarterly' , value:'Quarterly'},
      { name:'Yearly' , value:'Yearly'},
    ]
  }

  eventBasedTrigger = {
    workflowToTriggerList : [
      { name: 'Enhanced Corporate Due Diligence Workflow' , value:'Enhanced Corporate Due Diligence Workflow'},
      { name: 'Corporate Due Diligence Review Workflow' , value:'Corporate Due Diligence Review Workflow'},
      { name:'Trade Based Money Laundering Workflow' , value:'Trade Based Money Laundering Workflow'},
      { name:'Investment Due Diligence Workflow' , value:'Investment Due Diligence Workflow'},
      { name:'Merger Due Diligence Workflow' , value: 'Merger Due Diligence Workflow'},
      { name:'Acquisition Due Diligence Workflow' , value: 'Acquisition Due Diligence Workflow'},
      { name:'Corporate Loan Due Diligence Workflow' , value: 'Corporate Loan Due Diligence Workflow'},
      { name:'Standard KYB Due Diligence Workflow' , value: 'Standard KYB Due Diligence Workflow'}
    ],
    alertRemediationWorkflowList: [
      { name:'Standard Alert Remediation Workflow', value:'Standard Alert Remediation Workflow'},
      { name:'Advanced Alert Remediation Workflow', value:'Advanced Alert Remediation Workflow'}
    ],
    events:[
      { name: 'Risk Score' , value: 'riskScore', dropDown:[
        { name:'Risk Score dropdown', value: 'riskScore'}
      ]},
      { name: 'Document Expiry' , value: 'documentExpiry' , dropDown:[ { name:'Document Expiry dropdown' , value: 'documentExpiry'},{ name:'Document Expiry dropdown' , value: 'documentExpiry'},]},
      { name: 'AML Status Change' , value: 'aMLStatusChange', dropDown:[ { name: 'AML Status Change' , value: 'aMLStatusChange'},{ name: 'AML Status Change' , value: 'aMLStatusChange'},]},
      { name: 'Adverse Media Risk Change' , value: 'adverseMediaRiskChange', dropDown:[ { name: 'Adverse Media Risk Change' , value: 'adverseMediaRiskChange'},{ name: 'Adverse Media Risk Change' , value: 'adverseMediaRiskChange'}]},
      { name: 'Registry Status Change' , value: 'registryStatusChange', dropDown:[ { name: 'Registry Status Change' , value: 'registryStatusChange'},{ name: 'Registry Status Change' , value: 'registryStatusChange'}]},
      { name: 'Ownership Change' , value: 'ownershipChange', dropDown:[ {  name: 'Ownership Change' , value: 'ownershipChange'},{  name: 'Ownership Change' , value: 'ownershipChange'}]},
      { name: 'DowJones AML' , value: 'dowJonesAML', dropDown:[ {name: 'DowJones AML' , value: 'dowJonesAML'},{name: 'DowJones AML' , value: 'dowJonesAML'}]},
      { name: 'PEGA KYC' , value: 'PEGAKYC', dropDown:[ { name: 'PEGA KYC' , value: 'PEGAKYC'},{ name: 'PEGA KYC' , value: 'PEGAKYC'}]},
      { name: 'Hubspot CRM' , value: 'hubspotCRM', dropDown:[ { name: 'Hubspot CRM' , value: 'hubspotCRM'},{ name: 'Hubspot CRM' , value: 'hubspotCRM'}]},
      { name: 'Salesforce CRM' , value: 'salesforceCRM', dropDown:[ {name: 'Salesforce CRM' , value: 'salesforceCRM'},{name: 'Salesforce CRM' , value: 'salesforceCRM'}]},
      { name: 'Zoho CRM' , value: 'zohoCRM', dropDown:[ { name: 'Zoho CRM' , value: 'zohoCRM'},{ name: 'Zoho CRM' , value: 'zohoCRM'}]},
      { name: 'Pipedrive CRM' , value: 'pipedriveCRM', dropDown:[ { name: 'Pipedrive CRM' , value: 'pipedriveCRM'},{ name: 'Pipedrive CRM' , value: 'pipedriveCRM'}]},
    ]
  }

  addedFiled = {
    currentProperty:'',
    matchedField:null,
    value:null
  }

  conditionalEvents:FormGroup = this.formBuilder.group({
    allEvents: this.formBuilder.array([]),
  })

  newConditionalEvent(value): FormGroup {
    return this.formBuilder.group({
      [`${value}`] : ['' ,Validators.required]
    })
  }

  allEvents(): FormArray {
    return this.conditionalEvents.get('allEvents') as FormArray
  }

  addNRemoveEvents(value){
    if(this.allEvents().length){
      // this will keep the latest added one and removes old ones.
      this.allEvents().removeAt(0)
    }
    this.allEvents().push(this.newConditionalEvent(value))
  }

  constructor(private formBuilder : FormBuilder) {

    this.triggerAsNewTwo.get('eventOn').valueChanges.subscribe(data => {
      if(data !== ''){
        this.addedFiled.currentProperty = '';
        this.addedFiled.matchedField = null;
        this.addedFiled.currentProperty = data;
        this.addedFiled.matchedField = this.eventBasedTrigger.events.filter(event => event.value === data)[0];
        this.addNRemoveEvents(data);
      }
    });

    this.conditionalEvents.get('allEvents').valueChanges.subscribe(changes=> {
      // console.log(changes)
    })
  }

  ngOnInit(): void {
  }

  triggerNext(addType: string){
    if(addType == 'periodic-trigger'){
      // this.triggerOneSubmitted = true;
      this.triggerLastScreen = true;
      if(this.triggerAsNew.valid){
        // this.bsModalRef.hide();
        const payload = {
          
        };
        // this.onClose.next(payload);
      }
    }
    else if(addType == 'event-based-trigger'){
      this.triggerTwoSubmitted = true;
      // this.triggerLastScreen = true;
      
    }
  }

  schedule($event){
    this.showSchedule = $event;
  }

  get asNewTrigger(): { [key: string]: AbstractControl } {
    return this.triggerAsNew.controls;
  }

  get asNewTriggerTwo(): { [key: string]: AbstractControl } {
    return this.triggerAsNewTwo.controls;
  }



}
