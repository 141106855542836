import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageComponentService {

  pageList=[
    {
      path:"/home",
      pageId:"home"
    },
    {
      path:"/retail-onboarding",
      pageId:"retail-onboarding"
    },
    {
      path:"/legal-onboarding",
      pageId:"legal-onboarding"
    },
    {
      path:"/new-legal-onboarding",
      pageId:"new-legal-onboarding"
    },
    {
      path:"/corporate-check",
      pageId:"corporate-check"
    },
    // {
    //   path:"/retail-onboarding",
    //   pageId:"aml-check"
    // },
  ]

  constructor() { 

  }

  getPageId(path){
    let page=this.pageList.find(item=>item.path== path)
    console.log("the page in service: ", page)
    if(page){
      return page.pageId;
    }
    else{
      return null
    }
  }
}
